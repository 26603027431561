<app-item-description
  [chainId]="data.chainId"
  [item]="data.item"
  [slot]="data.slot"
  [hero]="data.hero"
  [isShowButtons]="data.isShowButtons"
  [isApplyItem]="data.isApplyItem"
  [applyItemLabel]="data.applyItemLabel"
  [isHideDurability]="data.isHideDurability"
  [isCanUse]="data.isCanUse"
  (takeOff)="takeOff($event)"
  (use)="use($event)"
  (closeDialog)="close($event)"
  (itemTransfer)="onItemTransfer()"
  (itemRepair)="onItemRepair()"
  (itemForge)="onItemForge()">
</app-item-description>
