import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { AchievementModel } from '@models/achievement.model';
import { Mediator } from '@services/mediator.service';
import { AchievementsActions } from '@shared/actions/achievement.actions';

@Component({
  selector: 'app-achievement',
  standalone: true,
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent {
  @Input() item: AchievementModel;

  @HostListener('click', [])
  onHide() {
    console.log('---onHide');
    this.mediator.dispatch(new AchievementsActions.Hide());
  }

  private mediator = inject(Mediator);
}
