import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { Formatter } from '@helpers/formatter';
import { RadioGroupModel } from '@models/radio-group.model';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { HeroControllerService } from '@services/onchain/hero-controller.service';
import { ItemControllerService } from '@services/onchain/item-controller.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { RadioGroupComponent } from '@shared/components/radio-group/radio-group.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { TetuGame } from '@shared/constants/addresses/TetuGame';
import { ITEM_TYPE } from '@shared/constants/items.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { formatUnits, parseUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { filter, finalize, forkJoin, takeUntil } from 'rxjs';

import { FragilityRestoredDialogComponent } from './components/fragility-restored-dialog/fragility-restored-dialog.component';
import { RepairSuccessDialogComponent } from './components/repair-success-dialog/repair-success-dialog.component';

const EMPTY_CATALYST = {
  itemId: 0,
  equippedSlot: ITEM_TYPE.NO_SLOT,
} as ItemEntity;

enum ACTION_TYPE {
  REPAIR = 'Repair',
  FRAGILITY = 'Fragility',
}

@Component({
  standalone: true,
  templateUrl: './repair-dialog.component.html',
  styleUrls: ['./repair-dialog.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    LoadingSmallComponent,
    ItemSlotComponent,
    ScratchComponent,
    ButtonClickDirective,
    BalanceComponent,
    ItemCardComponent,
    TranslateModule,
    RadioGroupComponent,
  ],
})
export class RepairDialogComponent implements OnInit {
  // common
  account: string;
  chainId: number;
  loading = true;

  heroAdr: string;
  heroId: number;
  itemAdr: string;
  itemId: number;

  MAIN_ROUTES = MAIN_ROUTES;

  hero: HeroEntity;

  targetItem: ItemEntity;

  catalystItems: ItemEntity[] = [];
  selectedCatalyst?: ItemEntity = EMPTY_CATALYST;

  catalystFragilityItems: ItemEntity[] = [];
  selectedFragilityCatalyst?: ItemEntity = EMPTY_CATALYST;

  accountBalance = 0n;
  accountBalanceFormatted = '0';
  isEnoughAllowance = false;
  isEnoughBalance = false;

  ACTION_TYPE = ACTION_TYPE;
  actionTypes: RadioGroupModel[] = [
    {
      id: ACTION_TYPE.REPAIR,
      label: ACTION_TYPE.REPAIR,
    },
  ];
  currentActionType: ACTION_TYPE = ACTION_TYPE.REPAIR;
  transactionLoading = false;

  constructor(
    @Inject(DIALOG_DATA)
    public data: { heroToken: string; heroId: number; itemMetaId: string; itemId: number },
    private destroy$: DestroyService,
    private logger: NGXLogger,
    private appStateService: AppStateService,
    private providerService: ProviderService,
    private heroControllerService: HeroControllerService,
    private itemControllerService: ItemControllerService,
    private tokenService: TokenService,
    private subgraphService: SubgraphService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: Dialog,
    private soundService: SoundService,
    private repairDialogRef: DialogRef<boolean, RepairDialogComponent>,
  ) {
    this.heroAdr = this.data.heroToken;
    this.heroId = this.data.heroId;
    this.itemAdr = this.data.itemMetaId;
    this.itemId = this.data.itemId;

    this.appStateService.setHeaderState({
      isAccountBalanceShown: true,
      isInventoryShown: true,
      backUrl: [MAIN_ROUTES.MAIN, MAIN_ROUTES.INVENTORY, this.heroAdr, this.heroId.toString()],
    });
  }

  ngOnInit(): void {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.heroControllerService.adjustFees();
        this.itemControllerService.adjustFees();
        this.tokenService.adjustFees();
        this.init();
      },
    );

    this.subgraphService
      .item$(this.itemAdr, this.itemId)
      .pipe(
        filter(item => Number(item.fragility) > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.actionTypes.push({
          id: ACTION_TYPE.FRAGILITY,
          label: ACTION_TYPE.FRAGILITY,
        });
      });
  }

  private init() {
    if (this.account && this.chainId) {
      this.loadHeroInfo();
      this.loadItems();
    }
  }

  private loadHeroInfo() {
    this.subgraphService
      .hero$(this.heroAdr, this.heroId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hero => {
        if (hero) {
          this.hero = hero;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private loadItems() {
    this.subgraphService
      .usersItems$(this.account)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(items => {
        this.targetItem = items.filter(
          item => item.itemId === this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase(),
        )[0] as ItemEntity;

        this.catalystItems = items
          .filter(item => item.itemId !== this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase())
          .map(i => i as ItemEntity);

        this.catalystFragilityItems = items
          .filter(item => item.itemId !== this.itemId && item.meta.name === TetuGame.ITEMS.OTHER_2)
          .map(i => i as ItemEntity);

        this.updateFeeBalance();

        this.loading = false;
      });
  }

  onSelectActionType({ id }) {
    this.currentActionType = id;
  }

  updateFeeBalance() {
    const feeToken = this.targetItem.meta.feeToken;
    if (feeToken) {
      forkJoin([
        this.tokenService.allowance$(feeToken.token.id, this.account, GET_CORE_ADDRESSES(this.chainId).controller),
        this.tokenService.balanceOf$(feeToken.token.id, this.account),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([allowance, balance]) => {
          this.isEnoughAllowance = allowance >= parseUnits(feeToken.amount, feeToken.token.decimals);
          this.isEnoughBalance = balance >= parseUnits(feeToken.amount, feeToken.token.decimals);
          this.accountBalance = balance;
          this.accountBalanceFormatted = Formatter.formatCurrency(+formatUnits(balance, feeToken.token.decimals));

          this.changeDetectorRef.detectChanges();
        });
    }
  }

  selectCatalyst(item: ItemEntity) {
    this.selectedCatalyst = item;
  }

  selectFragilityCatalyst(item: ItemEntity) {
    this.selectedFragilityCatalyst = item;
  }

  resetCatalyst() {
    if (this.currentActionType === ACTION_TYPE.REPAIR) {
      this.selectedCatalyst = EMPTY_CATALYST;
    }

    if (this.currentActionType === ACTION_TYPE.FRAGILITY) {
      this.selectedFragilityCatalyst = EMPTY_CATALYST;
    }
  }

  onItemSlotClick(item?: ItemEntity) {
    if (item && item.itemId !== 0) {
      this.dialog.open(ItemDescriptionDialogComponent, {
        panelClass: 'app-overlay-pane-full-width',
        data: { item, hero: this.hero, isShowButtons: false, chainId: this.chainId },
      });
    }
  }

  protected repair(): void {
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'repair' }).then();
    const isRepairDurability = this.currentActionType === ACTION_TYPE.REPAIR;

    (isRepairDurability
      ? this.itemControllerService.repairDurability$(
          this.chainId,
          this.targetItem.meta.id,
          this.targetItem.itemId,
          this.selectedCatalyst?.itemId ?? 0,
        )
      : this.itemControllerService.repairFragility$(
          this.chainId,
          this.targetItem.meta.id,
          this.targetItem.itemId,
          this.selectedCatalyst?.id ?? '',
          this.selectedCatalyst?.itemId ?? 0,
        )
    )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.selectedCatalyst = EMPTY_CATALYST;
        if (isRepairDurability) {
          this.openResult();
        } else {
          this.afterRepair();
        }
      });
  }

  private openResult() {
    this.subgraphService
      .item$(this.itemAdr, this.itemId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => this.afterRepair(item));
  }

  private afterRepair(item?: ItemEntity): void {
    const isRepairDurability = this.currentActionType === ACTION_TYPE.REPAIR;
    let success: boolean;

    let alertResult:
      | DialogRef<unknown, RepairSuccessDialogComponent>
      | DialogRef<unknown, FragilityRestoredDialogComponent>;

    this.transactionLoading = false;
    this.init();
    this.selectedCatalyst = undefined;
    this.changeDetectorRef.detectChanges();

    if (isRepairDurability) {
      success = item!.durability === item!.meta.durability;

      alertResult = this.dialog.open(RepairSuccessDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: { item: item, isSuccess: success },
      });
    } else {
      success = true;
      alertResult = this.dialog.open(FragilityRestoredDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: {},
      });
    }

    // TODO add fail sound
    this.soundService.play({ key: success ? 'repair' : 'repair' }).then();

    alertResult.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.logger.trace('dialog res', data, success);
      this.close(true);
    });
  }

  approveFeeToken() {
    if (this.targetItem.meta.feeToken) {
      this.transactionLoading = true;
      this.changeDetectorRef.detectChanges();
      this.tokenService
        .approve$(
          this.account,
          this.targetItem.meta.feeToken.token.id,
          GET_CORE_ADDRESSES(this.chainId).controller,
          BigInt(NUMBERS.MAX_UINT),
        )
        .pipe(
          finalize(() => {
            this.transactionLoading = false;
            this.changeDetectorRef.detectChanges();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.updateFeeBalance();
        });
    }
  }

  buyTokens() {
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'buy_coins' });

    const feeTokenData = this.targetItem.meta.feeToken;
    if (feeTokenData) {
      this.tokenService
        .buyTokens$(
          this.account,
          '',
          feeTokenData.token.id,
          parseUnits(feeTokenData.amount, feeTokenData.token.decimals),
          this.chainId,
        )
        .pipe(
          finalize(() => {
            this.transactionLoading = false;
            this.changeDetectorRef.detectChanges();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.updateFeeBalance();
        });
    }
  }

  close(result = false): void {
    this.repairDialogRef.close(result);
  }
}
