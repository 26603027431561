@if (hero || headerState?.isBackButtonShown) {
  <div
    class="elements g-flex g-flex--align-center g-flex--space-between"
    [class.g-flex--space-between]="!hero">
    <div class="left-menu g-flex g-flex--align-center g-flex__item-fixed">
      @if (headerState?.isBackButtonShown) {
        <div
          class="btn-back g-flex__item-fixed g-flex g-flex--align-center g-flex--justify-center pointer"
          (click)="goBack()">
          <img
            src="assets/images/ui/icons/other/left.png"
            alt="" />
        </div>
      }

      @if (!isHideMenuButton) {
        <div
          class="menu g-flex__item-fixed g-flex g-flex--align-center pointer"
          (click)="handleMenu()">
          <img
            class="menu__icon"
            src="assets/images/ui/icons/other/menu.png"
            alt="" />

          <div class="menu__text app-btn-medium_font-size">
            {{ 'menu.title' | translate }}
          </div>
        </div>
      }
    </div>

    @if (hero) {
      <div class="notifications g-flex__item g-flex g-flex--align-center g-flex--justify-center">
        @if (isAbleLevelUp) {
          <img
            class="account__wallet-icon"
            src="assets/images/ui/icons/notifications/level-up.png"
            alt=""
            (click)="onShowLevelUpDialog()" />
        }

        @if (isAbleToClaimRewards) {
          <img
            class="account__wallet-icon"
            src="assets/images/ui/icons/notifications/reinforcement.png"
            alt=""
            (click)="onShowReinforcementDialog()" />
        }

        @if (isNeedRepair) {
          <img
            class="account__wallet-icon"
            src="assets/images/ui/icons/notifications/repair.png"
            alt=""
            (click)="onShowRepairDialog()" />
        }
      </div>
    }

    @if (account && headerState?.isAccountAddressShown) {
      <div class="account g-flex__item-fixed g-flex g-flex--align-center g-flex--justify-end gap-12">
        <img
          class="web3-auth pointer"
          [src]="
            isShowThunderbolt()
              ? '/assets/images/ui/icons/other/thunderbolt.png'
              : '/assets/images/ui/buttons/other/thunderbolt/disabled.png'
          "
          alt=""
          (click)="onSessionSettings()" />

        <div
          class="g-flex g-flex--align-center g-flex--justify-center"
          (click)="openAccountModal()">
          <div class="account__address app-btn-small_font-size">
            {{ !userName ? accountFormatted : userName }}
          </div>

          <img
            class="account__wallet-icon"
            [src]="iconName"
            alt="" />

          <div class="account__text app-btn-small_font-size">
            {{ networkTokenBalance }}
          </div>
        </div>
      </div>
    }

    <div
      class="menu__border app-divider-scratches-border-v-medium-down no-action"
      [class.menu__border--show]="menuState.isOpen"></div>
  </div>
}

@if (hero || headerState?.isBackButtonShown) {
  <div
    class="border g-flex g-flex--justify-center"
    [class.border--show]="menuState.isOpen"
    [class.border--no-hero]="!hero">
    <img
      class="border__corner border__corner-left"
      src="assets/images/ui/menu/border-left-part-corner-left.avif"
      alt="" />

    <img
      class="menu-border"
      src="assets/images/ui/menu/border-left-part-expandable.avif"
      alt="" />

    <img
      class="menu-border corner-right"
      src="assets/images/ui/menu/border-left-part-corner-right.avif"
      alt="" />

    <img
      class="menu-center"
      src="assets/images/ui/menu/border-middle.avif"
      alt="" />

    <img
      class="menu-border corner-left"
      src="assets/images/ui/menu/border-right-part-corner-left.avif"
      alt="" />

    <img
      class="menu-border"
      src="assets/images/ui/menu/border-right-part-expandable.avif"
      alt="" />

    <div
      class="border-right"
      [class.border-right--show]="menuState.isOpen"></div>

    <img
      class="border__corner border__corner-right"
      src="assets/images/ui/menu/border-right-part-corner-right.avif"
      alt="" />
  </div>
}

@if (!menuState.isOpen && !isHideMenuButtons) {
  <div
    class="btns gap-10"
    [class.btns--no-bars]="!isShowBars || !hero">
    <div
      class="menu-bottle"
      [class.menu-bottle--disabled]="!hero"
      (click)="openBottlesDialog()"></div>

    <div
      class="menu-help"
      [class]="isHasTutorial ? 'menu-help-active' : 'menu-help'"
      (click)="openTutorial()"></div>
  </div>
}

@if (!menuState.isOpen && !isHideMenuButtons) {
  <div
    class="bar-bg"
    [class.bar-bg--no-bars]="!isShowBars || !hero"></div>
}

<div
  class="content g-flex-column"
  [class.content--no-hero]="!hero"
  [class.content--show]="menuState.isOpen">
  <div
    class="content__items g-flex--justify-center"
    [class.g-flex-column]="hero"
    [class.g-flex]="!hero">
    @if (hero) {
      <div class="hero">
        <div class="g-flex">
          <div class="g-flex-column g-flex--align-center">
            <div class="avatar-container">
              <app-avatar
                class="avatar"
                avatarType="small"
                [imagePath]="avatarUrl"
                [isCenteredImage]="true">
              </app-avatar>

              <div class="level level--help app-lvl-default g-flex g-flex--align-center g-flex--justify-center">
                <div class="level__text">
                  {{ hero.stats.level }}
                </div>

                @if (isAbleLevelUp) {
                  <img
                    class="level__up"
                    src="assets/images/ui/icons/other/level-up.png"
                    alt="" />
                }
              </div>
            </div>

            <app-life-chances
              class="life-chances"
              [lifeChance]="hero.stats.lifeChances">
            </app-life-chances>
          </div>

          <div class="hero-stat g-flex-column">
            <div class="color-nickname">
              {{ hero.uniqName }}
            </div>

            <div class="hero-stat__bars g-flex-column">
              <app-bar-stat
                barType="exp"
                size="large"
                [percentageValue]="expBarWidth()"
                [label]="heroExpPerc() >= 100 ? 'up to ' + virtualLevel + ' level' : heroExpPerc() + ' %'">
              </app-bar-stat>

              <app-bar-stat
                barType="hp"
                size="large"
                [percentageValue]="hero.stats.life / heroAttributes.life"
                [label]="hero.stats.life + ' / ' + heroAttributes.life">
              </app-bar-stat>

              <app-bar-stat
                barType="mp"
                size="large"
                [percentageValue]="hero.stats.mana / heroAttributes.mana"
                [label]="hero.stats.mana + ' / ' + heroAttributes.mana">
              </app-bar-stat>
            </div>
          </div>
        </div>

        <!--
        <div class="defence g-flex g-flex--align-center g-flex--space-between app-btn-small_font-size">
          <app-hero-stat-item
            [item]="{
              icon: heroAttributes.getAttributeIcon(ATTRIBUTES.DAMAGE_MIN),
              name: heroAttributes.getAttributeName(ATTRIBUTES.DAMAGE_MIN),
              text: heroAttributes.damageMin + ' - ' + heroAttributes.damageMax,
              description: heroAttributes.getAttributeDescription(ATTRIBUTES.DAMAGE_MIN),
              attributeIndex: ATTRIBUTES.DAMAGE_MIN,
            }"
            [isCompact]="true"
            [isMediumFont]="true"
            [iconCustomWidth]="95"
            [useColors]="false">
          </app-hero-stat-item>

          <app-hero-stat-item
            [item]="{
              icon: heroAttributes.getAttributeIcon(ATTRIBUTES.ATTACK_RATING),
              name: heroAttributes.getAttributeName(ATTRIBUTES.ATTACK_RATING),
              text: heroAttributes.attackRating,
              description: heroAttributes.getAttributeDescription(ATTRIBUTES.ATTACK_RATING),
              attributeIndex: ATTRIBUTES.ATTACK_RATING,
            }"
            [isCompact]="true"
            [isMediumFont]="true"
            [iconCustomWidth]="95"
            [useColors]="false">
          </app-hero-stat-item>

          <app-hero-stat-item
            [item]="{
              icon: heroAttributes.getAttributeIcon(ATTRIBUTES.DEFENSE),
              name: heroAttributes.getAttributeName(ATTRIBUTES.DEFENSE),
              text: heroAttributes.defense,
              description: heroAttributes.getAttributeDescription(ATTRIBUTES.DEFENSE),
              attributeIndex: ATTRIBUTES.DEFENSE,
            }"
            [isCompact]="true"
            [isMediumFont]="true"
            [iconCustomWidth]="95"
            [useColors]="false">
          </app-hero-stat-item>

          <app-hero-stat-item
            [item]="{
              icon: heroAttributes.getAttributeIcon(ATTRIBUTES.BLOCK_RATING),
              name: heroAttributes.getAttributeName(ATTRIBUTES.BLOCK_RATING),
              text: heroAttributes.blockRating,
              description: heroAttributes.getAttributeDescription(ATTRIBUTES.BLOCK_RATING),
              attributeIndex: ATTRIBUTES.BLOCK_RATING,
            }"
            [isCompact]="true"
            [isMediumFont]="true"
            [isShowAdjusted]="true"
            [iconCustomWidth]="95"
            [useColors]="false">
          </app-hero-stat-item>
        </div>

        <div class="hero-stat__elements hero-stat__elements-core g-flex g-flex--space-between app-btn-small_font-size">
          <app-hero-stat-item
            *ngFor="let stat of coreStatInfo"
            class="stat-item"
            [isCompact]="true"
            [isMediumFont]="true"
            [iconCustomWidth]="95"
            [isShowAdjusted]="true"
            [item]="stat"
            [useColors]="false">
          </app-hero-stat-item>
        </div>

        <div
          class="hero-stat__elements hero-stat__elements-additional g-flex g-flex--space-evenly app-btn-small_font-size">
          <app-hero-stat-item
            *ngFor="let stat of additionalStatInfo"
            class="stat-item"
            [isCompact]="true"
            [item]="stat"
            [isMediumFont]="true"
            [iconCustomWidth]="95"
            [useColors]="false">
          </app-hero-stat-item>
        </div>
        -->
      </div>
    }

    <div class="buttons g-flex g-flex--wrap g-flex--align-center g-flex--justify-center">
      <button
        class="buttons__item app-btn-red-2 btn-settings g-flex g-flex--align-center pointer"
        [class.buttons__item--hero]="hero"
        [class.btn-settings--no-hero]="!hero"
        (click)="openSettings()">
        <img
          class="buttons__item-icon"
          src="assets/images/ui/icons/other/settings.png"
          alt="" />
        {{ 'menu.buttons.settings' | translate }}
      </button>

      <button
        class="btn-market buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
        [class.btn-market--no-hero]="!hero"
        [class.buttons__item--hero]="hero"
        (click)="openMarketplace()">
        <img
          class="buttons__item-icon"
          src="assets/images/ui/icons/other/marketplace.png"
          alt="" />
        {{ 'menu.buttons.marketplace' | translate }}
      </button>

      <button
        class="buttons__item app-btn-red-2 btn-heroes g-flex g-flex--align-center pointer"
        [class.btn-heroes--no-hero]="!hero"
        [class.buttons__item--hero]="hero"
        (click)="openHeroes()">
        <img
          class="buttons__item-icon"
          src="assets/images/ui/icons/other/helmet.png"
          alt="" />
        {{ 'menu.buttons.heroes' | translate }}
      </button>

      @if (hero) {
        <button
          class="buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
          [class.buttons__item--hero]="hero"
          appButtonClick
          [isDisabled]="!headerState?.isInventoryShown"
          (hostClick)="openInventory()">
          <img
            class="buttons__item-icon"
            src="assets/images/ui/icons/other/inventory.png"
            alt="" />
          {{ 'menu.buttons.inventory' | translate }}
        </button>
      }

      <button
        class="btn-top-list buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
        [class.btn-top-list--no-hero]="!hero"
        [class.buttons__item--hero]="hero"
        (click)="isSonicChain() ? openLeaderboard() : openTopList()">
        <img
          class="buttons__item-icon g-flex__item-fixed"
          src="assets/images/ui/icons/other/top-list.png"
          alt="" />
        {{ isSonicChain() ? ('menu.buttons.leaderboard' | translate) : ('menu.buttons.top-list' | translate) }}
      </button>

      @if (hero) {
        <button
          class="buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
          [class.buttons__item--hero]="hero"
          (click)="openTransform()">
          <img
            class="buttons__item-icon g-flex__item-fixed"
            src="assets/images/ui/icons/other/transmutation.png"
            alt="" />
          {{ 'menu.buttons.transmutation' | translate }}
        </button>
      }

      @if (hero && !hero.dungeon) {
        <button
          class="buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
          [class.buttons__item--hero]="hero"
          appButtonClick
          infoDesc="Hero is staked in reinforcement."
          [isDisabled]="hero.staked"
          (hostClick)="goToMap()">
          <img
            class="buttons__item-icon g-flex__item-fixed"
            src="assets/images/ui/icons/other/map.png"
            alt="" />
          {{ 'menu.buttons.go-to-map' | translate }}
        </button>
      }

      <button
        class="btn-guilds buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
        [class.btn-market--no-hero]="!hero"
        (click)="openGuilds()">
        <img
          class="buttons__item-icon buttons__item-icon--guilds"
          src="assets/images/ui/icons/other/guilds.png"
          alt="" />
        {{ 'menu.buttons.guilds' | translate }}
      </button>

      @if (hero && hero.dungeon) {
        <button
          class="buttons__item app-btn-red-2 g-flex g-flex--align-center pointer"
          [class.buttons__item--hero]="hero"
          appButtonClick
          infoDesc="Hero is staked in reinforcement."
          [isDisabled]="hero.staked"
          (hostClick)="returnToAdventure()">
          <img
            class="buttons__item-icon g-flex__item-fixed"
            src="assets/images/ui/icons/other/map.png"
            alt="" />
          {{ 'menu.buttons.continue' | translate }}
        </button>
      }

      @if (!hero) {
        <button
          class="no-hero-container g-flex g-flex--align-center g-flex--justify-center app-btn-red-huge-1 width-100-percent"
          (click)="createHero()">
          {{ 'menu.buttons.create-hero' | translate }}
        </button>
      }
    </div>
  </div>

  <app-daily-points class="daily-points"></app-daily-points>

  <div
    class="balance g-flex g-flex--align-center g-flex--justify-center"
    [class.balance--no-hero]="!hero">
    <app-balance
      tokenType="sacra"
      size="small"
      [isReverse]="false"
      [balance]="gameTokenBalance"
      [isShowBalanceUsd]="true"
      (click)="buyTokens('sacra')">
    </app-balance>

    @if (!isTokensTheSame) {
      <!--      <app-balance-->
      <!--        tokenType="usdc"-->
      <!--        size="small"-->
      <!--        [isReverse]="false"-->
      <!--        [balance]="strengthToken"-->
      <!--        [isShowBalanceUsd]="true"-->
      <!--        (click)="buyTokens('usdc')">-->
      <!--      </app-balance>-->

      <app-balance
        [token]="magicTokenAdr"
        size="small"
        [isReverse]="false"
        [balance]="magicTokenBalance"
        [isShowBalanceUsd]="true"
        (click)="buyTokens('usdc')">
      </app-balance>

      <!--      <app-balance-->
      <!--        tokenType="tetu"-->
      <!--        size="small"-->
      <!--        [isReverse]="false"-->
      <!--        [balance]="dexterityToken"-->
      <!--        [isShowBalanceUsd]="true"-->
      <!--        (click)="buyTokens('tetu')">-->
      <!--      </app-balance>-->
    }
  </div>
</div>

@if (hero && isShowBars) {
  <div
    class="bar g-flex g-flex--space-between"
    [class.bar--show]="menuState.isOpen">
    <app-bar-stat
      barType="hp"
      size="menu"
      [isShowPoint]="false"
      [percentageValue]="hero.stats.life / heroAttributes.life">
    </app-bar-stat>

    <app-bar-stat
      barType="mp"
      size="menu"
      [isShowPoint]="false"
      [isRotated]="true"
      [percentageValue]="hero.stats.mana / heroAttributes.mana">
    </app-bar-stat>
  </div>
}

@if (headerState?.isBackButtonShown) {
  <div
    class="bg"
    [class.bg--show]="menuState.isOpen"
    [class.bg--no-hero]="!hero"></div>
}
