import { Injectable } from '@angular/core';
import { SacraRelay__factory } from '@data/abi';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { adjustGasLimit } from '@shared/utils';
import { parseUnits } from 'ethers';
import { catchError, from, retry, forkJoin, concatMap, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RelayService extends FeesExtension {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
  ) {
    super();
  }

  // --- FACTORIES ---

  private createRelay(chainId: number, signer?: string) {
    return SacraRelay__factory.connect(
      GET_CORE_ADDRESSES(chainId).relay,
      this.providerService.getProviderForRead(signer),
    );
  }

  // --- VIEWS ---

  userInfo$(chainId: number, account: string) {
    return from(this.createRelay(chainId).userInfo(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS ---

  createDelegatedTx(data: TransactionDataModel, user: string, delegator: string) {
    return forkJoin([from(data.txPopulated), this.userInfo$(data.chainId ?? -1, user)]).pipe(
      switchMap(([txPopulated, userInfo]) => {
        return from(
          this.createRelay(data.chainId ?? -1, delegator).callFromDelegator.populateTransaction({
            chainId: data.chainId ?? -1,
            target: txPopulated.to,
            data: txPopulated.data,
            user: user,
            userNonce: userInfo.nonce,
            userDeadline: Math.floor(new Date().getTime() / 1000) + 60 * 3,
          }),
        );
      }),
    );
  }

  delegate$(chainId: number, delegator: string, amount: number) {
    return from(this.createRelay(chainId).delegate.estimateGas(delegator)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Delegate',
            subgraphWaitUserData: false,
            showLoadingScreen: true,
            txPopulated: this.createRelay(chainId).delegate.populateTransaction(delegator),
            value: parseUnits(amount.toFixed(18)),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  closeDelegation$(chainId: number, user: string, amount: number, delegator: string) {
    console.log('close Delegation', chainId, user, amount, delegator);
    return from(this.createRelay(chainId, delegator).closeDelegation.estimateGas(user)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Close delegation',
            subgraphWaitUserData: false,
            showLoadingScreen: false,
            txPopulated: this.createRelay(chainId, delegator).closeDelegation.populateTransaction(user),
            value: parseUnits(amount.toFixed(18)),
            gasLimit: adjustGasLimit(gas, 255081n),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            useDelegatedPK: true,
            isSponsoredRelayPossible: false,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }
}
