import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';

@Component({
  standalone: true,
  templateUrl: './fragility-restored-dialog.component.html',
  styleUrls: ['./fragility-restored-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, TranslateModule],
})
export class FragilityRestoredDialogComponent {
  constructor(private dialogRef: DialogRef<string, FragilityRestoredDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
