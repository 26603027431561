import { MetaInfo } from '@models/story/story-text';
import {
  getDungeonNameForUri,
  getMonsterNameForUri,
  geUriForName,
  monsterIdToName,
} from '@shared/constants/addresses/addresses.constant';
import { SpecificChambers } from '@shared/constants/addresses/AddressesTypes';
import { TetuGame } from '@shared/constants/addresses/TetuGame';
import { DUNGEON_NAMES } from '@shared/constants/dungeon.constant';
import { genObjectId } from '@shared/utils';

const MONSTER_NAMES = new Map<number, string>([
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_32, 'Rebellious Peasant'],
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_34, 'Rebellious Peasant'],
  [TetuGame.MONSTERS.TOUGH_DESERTER_32, 'Tough Deserter'],
  [TetuGame.MONSTERS.TOUGH_DESERTER_34, 'Tough Deserter'],
  [TetuGame.MONSTERS.BLOODTHIRSTY_MADMAN_32, 'Bloodthirsty Madman'],
  [TetuGame.MONSTERS.BLOODTHIRSTY_MADMAN_34, 'Bloodthirsty Madman'],
  [TetuGame.MONSTERS.FEROCIOUS_WOLFS_34, 'Ferocious Wolfs'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_32, 'Drunk Crossbowman'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_34, 'Drunk Crossbowman'],
  [TetuGame.MONSTERS.VILE_SERPENT_35, 'Vile Serpent'],
  [TetuGame.MONSTERS.THE_MAN_EATING_BEAR_35, 'The Man-Eating Bear'],
  [TetuGame.MONSTERS.BANDIT_CROWD_10, 'Bandit Crowd'],
  [TetuGame.MONSTERS.HANGMANS_PATROL_33, 'Hangmans Patrol'],
  [TetuGame.MONSTERS.MIDNIGHTER_2, 'Midnighter'],
  [TetuGame.MONSTERS.FOREST_CHORT_35, 'Forest Chort'],
  [TetuGame.MONSTERS.INFECTED_SPIRIT_OF_THE_FOREST_3, 'Infected Spirit of the Forest'],
  [TetuGame.MONSTERS.RESTLESS_SPIRIT_10, 'Restless Spirit'],
  [TetuGame.MONSTERS.MOCK1, 'MOCK1'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_JUNIOR_32, 'Infected Deadman'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_JUNIOR_34, 'Infected Deadman'],
  [TetuGame.MONSTERS.THRALL_10, 'Thrall'],
  [TetuGame.MONSTERS.SAVAGE_10, 'Savage'],
  [TetuGame.MONSTERS.MAGE_10, 'Mage'],
  [TetuGame.MONSTERS.ASSASSIN_10, 'Assassin'],
  [TetuGame.MONSTERS.MOCK2, 'MOCK2'],
  [TetuGame.MONSTERS.BLOODCURDLING_WOLFS_34, 'Bloodcurdling Wolfs'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_34, 'Somber Hangman'],
  [TetuGame.MONSTERS.THRALL_HUNTER_34, 'Thrall Hunter'],
  [TetuGame.MONSTERS.WILDED_ANCHORITE_34, 'Wilded Anchorite'],
  [TetuGame.MONSTERS.CHEERFUL_HANGMAN_34, 'Cheerful Hangman'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_32, 'Somber Hangman'],
  [TetuGame.MONSTERS.THRALL_HUNTER_32, 'Thrall Hunter'],
  [TetuGame.MONSTERS.WILDED_ANCHORITE_32, 'Wilded Anchorite'],
  [TetuGame.MONSTERS.CHEERFUL_HANGMAN_32, 'Cheerful Hangman'],
  [TetuGame.MONSTERS.FOREST_SHAMAN_35, 'Forest Shaman'],
  [TetuGame.MONSTERS.FILTHY_WILLY_35, 'Filthy Willy'],
  [TetuGame.MONSTERS.FOREST_SHAMAN_33, 'Forest Shaman'],
  [TetuGame.MONSTERS.FILTHY_WILLY_33, 'Filthy Willy'],
  [TetuGame.MONSTERS.RUINS_KEEPER_10, 'Ruins Keeper'],
  [TetuGame.MONSTERS.STRABAN_DOUCHEBAGS_AVENGERS_10, 'Straban Douchebags Avengers'],
  [TetuGame.MONSTERS.FUNH_3, 'Funh'],
  [TetuGame.MONSTERS.DEMOLDER_WARRIOR_34, 'Demolder Warrior'],
  [TetuGame.MONSTERS.DEMOLDER_SERGEANT_34, 'Demolder Sergeant'],
  [TetuGame.MONSTERS.DEMOLDER_BOWMAN_34, 'Demolder Bowman'],
  [TetuGame.MONSTERS.RUNE_PRIEST_34, 'Rune Priest'],
  [TetuGame.MONSTERS.DEMOLDER_HEADBRINGER_34, 'Demolder Headbringer'],
  [TetuGame.MONSTERS.ANCIENT_SERPENT_35, 'Ancient Serpent'],
  [TetuGame.MONSTERS.RUNE_KING_35, 'Rune King'],
  [TetuGame.MONSTERS.CHASER_35, 'Chaser'],
  [TetuGame.MONSTERS.A_LITTLE_MORE_BANDITS_THAN_USUAL_10, 'A Little More Bandits Than Usual'],
  [TetuGame.MONSTERS.OGRE_BARON_10, 'Ogre Baron'],
  [TetuGame.MONSTERS.ELITE_PRINCE_FELLOWS_10, 'Elite Prince Fellows'],
  [TetuGame.MONSTERS.UNFRIENDLY_HOUND_34, 'Unfriendly Hound'],
  [TetuGame.MONSTERS.BROTHER_DAMOUR_10, 'Brother Damour'],
  [TetuGame.MONSTERS.HUNGRY_BROOD_3, 'Hungry Brood'],
  [TetuGame.MONSTERS.MOCK3, 'MOCK3'],
  [TetuGame.MONSTERS.GIANT_WASP_34, 'Giant Wasp'],
  [TetuGame.MONSTERS.MOONHUNTER_34, 'Moonhunter'],
  [TetuGame.MONSTERS.GELDED_ONE_34, 'Gelded One'],
  [TetuGame.MONSTERS.RUNEBEARER_34, 'Runebearer'],
  [TetuGame.MONSTERS.DEMOLDER_BERSERK_34, 'Demolder Berserk'],
  [TetuGame.MONSTERS.SWAMP_TROLL_35, 'Swamp Troll'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_35, 'Fused Infected Deadman'],
  [TetuGame.MONSTERS.LOST_BROTHER_DAMOUR_10, 'Lost Brother Damour'],
  [TetuGame.MONSTERS.BARD_CHICK_35, 'Bard Chick'],
  [TetuGame.MONSTERS.WASP_QUEEN_35, 'Wasp Queen'],
  [TetuGame.MONSTERS.CULTIST_BOY_OF_MOTHER_34, 'Cultist Boy of Mother'],
  [TetuGame.MONSTERS.CULTIST_GIRL_OF_MOTHER_34, 'Cultist Girl of Mother'],
  [TetuGame.MONSTERS.HAKKAMANDA_10, 'Hakkamanda'],
  [TetuGame.MONSTERS.MELADZI_10, 'Meladzi'],
  [TetuGame.MONSTERS.PUG_PANIN_10, 'Pug Panin'],
  [TetuGame.MONSTERS.LEGION_COMMANDER_DOGWAN_10, 'Legion Commander Dogwan'],
  [TetuGame.MONSTERS.RUNE_KING_VISWANATHAN_10, 'Rune King Viswanathan'],
  [TetuGame.MONSTERS.GIRLS_AFTER_PARTY_35, 'Girls After Party'],
  [TetuGame.MONSTERS.MOTHER_MIOLANIA_3, 'Mother Miolania'],
  [TetuGame.MONSTERS.HANGMANS_PATROL_F2P, 'Hangmans Patrol'],
  [TetuGame.MONSTERS.A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P, 'A Little More Bandits Than Usual'],
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_F2P, 'Rebellious Peasant'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_F2P, 'Drunk Crossbowman'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_F2P, 'Somber Hangman'],
  [TetuGame.MONSTERS.FILTHY_WILLY_F2P, 'Filthy Willy'],
  [TetuGame.MONSTERS.BARBARIAN_BITCH_10, 'Barbarian Bitch'],
  [TetuGame.MONSTERS.BANISHED_PEASANT_10, 'Banished Peasant'],
  [TetuGame.MONSTERS.STARVING_WOLFS_10, 'Starving Wolfs'],
  [TetuGame.MONSTERS.PEDOPHILE_HUNTER_10, 'Pedophile Hunter'],
  [TetuGame.MONSTERS.FRESH_INFECTED_DEADMAN_10, 'Fresh Infected Deadman'],
  [TetuGame.MONSTERS.STYLE_POLICE_10, 'Style Police'],
  [TetuGame.MONSTERS.BAD_BEAR_10, 'Bad Bear'],
  [TetuGame.MONSTERS.ORDINARY_GOOSE_32, 'Ordinary Goose'],
  [TetuGame.MONSTERS.ORDINARY_GOOSE_34, 'Ordinary Goose'],
  [TetuGame.MONSTERS.LOCAL_DUDE_32, 'Local Dude'],
  [TetuGame.MONSTERS.LOCAL_DUDE_34, 'Local Dude'],
  [TetuGame.MONSTERS.HUNGRY_DOG_32, 'Hungry Dog'],
  [TetuGame.MONSTERS.HUNGRY_DOG_34, 'Hungry Dog'],
  [TetuGame.MONSTERS.ROWDY_10, 'Rowdy'],
  [TetuGame.MONSTERS.OLD_WHORE_10, 'Old Whore'],
  [TetuGame.MONSTERS.TRAINING_BUDDY_33, 'Training Buddy'],
  [TetuGame.MONSTERS.FEROCIOUS_WIFE_33, 'Ferocious Wife'],
  [TetuGame.MONSTERS.FUGITIVE_KUDJA_33, 'Fugitive Kudja'],
  [TetuGame.MONSTERS.FUGITIVE_KUDJA_35, 'Fugitive Kudja'],
  [TetuGame.MONSTERS.SMELLY_CANNIBAL_34, 'Smelly Cannibal'],
  [TetuGame.MONSTERS.T_POSER_33, 'T-poser'],
  [TetuGame.MONSTERS.T_POSER_35, 'T-poser'],
  [TetuGame.MONSTERS.BAD_SEED_33, 'Bad Seed'],
  [TetuGame.MONSTERS.BAD_SEED_35, 'Bad Seed'],
  [TetuGame.MONSTERS.RIPE_MIDNIGHTER_2, 'Ripe Midnighter'],
  [TetuGame.MONSTERS.JUICY_MIDNIGHTER_2, 'Juicy Midnighter'],
  [TetuGame.MONSTERS.MERGULLIAN_HEADHUNTER_34, 'Mergullian Headhunter'],
  [TetuGame.MONSTERS.SMALL_SPIDER_34, 'Small Spider'],
  [TetuGame.MONSTERS.YOUNG_TROLL_35, 'Young Troll'],
  [TetuGame.MONSTERS.FACELESS_10, 'Faceless'],
  [TetuGame.MONSTERS.LONE_BARD_35, 'Lone Bard'],
  [TetuGame.MONSTERS.FOREST_WITCH_35, 'Forest Witch'],
  [TetuGame.MONSTERS.DARK_ADVENTURERS_35, 'Dark  Adventurers'],
  [TetuGame.MONSTERS.SPICY_MIDNIGHTER_2, 'Spicy Midnighter'],
  [TetuGame.MONSTERS.DARK_ESSENCE_35, 'Dark Essence'],
  [TetuGame.MONSTERS.STRABAN_KNIGHT_10, "Straban's Knight"],
  [TetuGame.MONSTERS.WOUNDED_DEMOLDER_10, 'Wounded Demolder'],
  [TetuGame.MONSTERS.HANGOVER_WILLY_10, 'Hangover Willy'],
  [TetuGame.MONSTERS.VENOMOUS_SPIDER_10, 'Venomous Spider'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_SCALD_10, 'Demolder Champion Scald'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_THORN_10, 'Demolder Champion Thorn'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_GEKK_10, 'Demolder Champion Gekk'],
  [TetuGame.MONSTERS.MIGHTY_RUIN_KEEPER_10, 'Mighty Ruin Keeper'],
  [TetuGame.MONSTERS.KNIGHT_HAMMERGINA_10, 'Knight Hammergina'],
  [TetuGame.MONSTERS.FULLMOON_DARK_ESSENCE_10, 'Fullmoon Dark Essence'],
  [TetuGame.MONSTERS.SUPERGOOSE_10, 'Supergoose'],
]);

export const MONSTER_SYMBOLS = new Map<number, string>([
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_32, 'REBELLIOUS_PEASANT'],
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_34, 'REBELLIOUS_PEASANT'],
  [TetuGame.MONSTERS.TOUGH_DESERTER_32, 'TOUGH_DESERTER'],
  [TetuGame.MONSTERS.TOUGH_DESERTER_34, 'TOUGH_DESERTER'],
  [TetuGame.MONSTERS.BLOODTHIRSTY_MADMAN_32, 'BLOODTHIRSTY_MADMAN'],
  [TetuGame.MONSTERS.BLOODTHIRSTY_MADMAN_34, 'BLOODTHIRSTY_MADMAN'],
  [TetuGame.MONSTERS.FEROCIOUS_WOLFS_34, 'FEROCIOUS_WOLFS'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_32, 'CROSSBOW_MAN'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_34, 'CROSSBOW_MAN'],
  [TetuGame.MONSTERS.VILE_SERPENT_35, 'VILE_SERPENT'],
  [TetuGame.MONSTERS.THE_MAN_EATING_BEAR_35, 'THE_MAN_EATING_BEAR'],
  [TetuGame.MONSTERS.BANDIT_CROWD_10, 'BANDIT_CROWD'],
  [TetuGame.MONSTERS.HANGMANS_PATROL_33, 'HANGMANS_PATROL'],
  [TetuGame.MONSTERS.MIDNIGHTER_2, 'MIDNIGHTER'],
  [TetuGame.MONSTERS.FOREST_CHORT_35, 'FOREST_CHORT'],
  [TetuGame.MONSTERS.INFECTED_SPIRIT_OF_THE_FOREST_3, 'INFECTED_SPIRIT_OF_THE_FOREST'],
  [TetuGame.MONSTERS.RESTLESS_SPIRIT_10, 'RESTLESS_SPIRIT'],
  [TetuGame.MONSTERS.MOCK1, 'REBELLIOUS_PEASANT'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_JUNIOR_32, 'INFECTED_DEADMAN_JUNIOR'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_JUNIOR_34, 'INFECTED_DEADMAN_JUNIOR'],
  [TetuGame.MONSTERS.THRALL_10, 'THRALL'],
  [TetuGame.MONSTERS.SAVAGE_10, 'SAVAGE'],
  [TetuGame.MONSTERS.MAGE_10, 'MAGE'],
  [TetuGame.MONSTERS.ASSASSIN_10, 'ASSASSIN'],
  [TetuGame.MONSTERS.MOCK2, 'REBELLIOUS_PEASANT'],
  [TetuGame.MONSTERS.BLOODCURDLING_WOLFS_34, 'BLOODCURDLING_WOLFS'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_34, 'SOMBER_HANGMAN'],
  [TetuGame.MONSTERS.THRALL_HUNTER_34, 'THRALL_HUNTER'],
  [TetuGame.MONSTERS.WILDED_ANCHORITE_34, 'WILDED_ANCHORITE'],
  [TetuGame.MONSTERS.CHEERFUL_HANGMAN_34, 'CHEERFUL_HANGMAN'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_32, 'SOMBER_HANGMAN'],
  [TetuGame.MONSTERS.THRALL_HUNTER_32, 'THRALL_HUNTER'],
  [TetuGame.MONSTERS.WILDED_ANCHORITE_32, 'WILDED_ANCHORITE'],
  [TetuGame.MONSTERS.CHEERFUL_HANGMAN_32, 'CHEERFUL_HANGMAN'],
  [TetuGame.MONSTERS.FOREST_SHAMAN_35, 'FOREST_SHAMAN'],
  [TetuGame.MONSTERS.FILTHY_WILLY_35, 'FILTHY_WILLY'],
  [TetuGame.MONSTERS.FOREST_SHAMAN_33, 'FOREST_SHAMAN'],
  [TetuGame.MONSTERS.FILTHY_WILLY_33, 'FILTHY_WILLY'],
  [TetuGame.MONSTERS.RUINS_KEEPER_10, 'RUINS_KEEPER'],
  [TetuGame.MONSTERS.STRABAN_DOUCHEBAGS_AVENGERS_10, 'STRABAN_DOUCHEBAGS_AVENGERS'],
  [TetuGame.MONSTERS.FUNH_3, 'FUNH'],
  [TetuGame.MONSTERS.DEMOLDER_WARRIOR_34, 'DEMOLDER_WARRIOR'],
  [TetuGame.MONSTERS.DEMOLDER_SERGEANT_34, 'DEMOLDER_SERGEANT'],
  [TetuGame.MONSTERS.DEMOLDER_BOWMAN_34, 'DEMOLDER_BOWMAN'],
  [TetuGame.MONSTERS.RUNE_PRIEST_34, 'RUNE_PRIEST'],
  [TetuGame.MONSTERS.DEMOLDER_HEADBRINGER_34, 'DEMOLDER_HEADBRINGER'],
  [TetuGame.MONSTERS.ANCIENT_SERPENT_35, 'ANCIENT_SERPENT'],
  [TetuGame.MONSTERS.RUNE_KING_35, 'RUNE_KING'],
  [TetuGame.MONSTERS.CHASER_35, 'CHASER'],
  [TetuGame.MONSTERS.A_LITTLE_MORE_BANDITS_THAN_USUAL_10, 'A_LITTLE_MORE_BANDITS_THAN_USUAL'],
  [TetuGame.MONSTERS.OGRE_BARON_10, 'OGRE_BARON'],
  [TetuGame.MONSTERS.ELITE_PRINCE_FELLOWS_10, 'ELITE_PRINCE_FELLOWS'],
  [TetuGame.MONSTERS.UNFRIENDLY_HOUND_34, 'UNFRIENDLY_HOUND'],
  [TetuGame.MONSTERS.BROTHER_DAMOUR_10, 'BROTHER_DAMOUR'],
  [TetuGame.MONSTERS.HUNGRY_BROOD_3, 'HUNGRY_BROOD'],
  [TetuGame.MONSTERS.MOCK3, 'MOCK3'],
  [TetuGame.MONSTERS.GIANT_WASP_34, 'GIANT_WASP'],
  [TetuGame.MONSTERS.MOONHUNTER_34, 'MOONHUNTER'],
  [TetuGame.MONSTERS.GELDED_ONE_34, 'GELDED_ONE'],
  [TetuGame.MONSTERS.RUNEBEARER_34, 'RUNEBEARER'],
  [TetuGame.MONSTERS.DEMOLDER_BERSERK_34, 'DEMOLDER_BERSERK'],
  [TetuGame.MONSTERS.SWAMP_TROLL_35, 'SWAMP_TROLL'],
  [TetuGame.MONSTERS.INFECTED_DEADMAN_35, 'INFECTED_DEADMAN'],
  [TetuGame.MONSTERS.LOST_BROTHER_DAMOUR_10, 'LOST_BROTHER_DAMOUR'],
  [TetuGame.MONSTERS.BARD_CHICK_35, 'BARD_CHICK'],
  [TetuGame.MONSTERS.WASP_QUEEN_35, 'WASP_QUEEN'],
  [TetuGame.MONSTERS.CULTIST_BOY_OF_MOTHER_34, 'CULTIST_BOY_OF_MOTHER'],
  [TetuGame.MONSTERS.CULTIST_GIRL_OF_MOTHER_34, 'CULTIST_GIRL_OF_MOTHER'],
  [TetuGame.MONSTERS.HAKKAMANDA_10, 'HAKKAMANDA'],
  [TetuGame.MONSTERS.MELADZI_10, 'MELADZI'],
  [TetuGame.MONSTERS.PUG_PANIN_10, 'PUG_PANIN'],
  [TetuGame.MONSTERS.LEGION_COMMANDER_DOGWAN_10, 'LEGION_COMMANDER_DOGWAN'],
  [TetuGame.MONSTERS.RUNE_KING_VISWANATHAN_10, 'RUNE_KING_VISWANATHAN'],
  [TetuGame.MONSTERS.GIRLS_AFTER_PARTY_35, 'GIRLS_AFTER_PARTY'],
  [TetuGame.MONSTERS.MOTHER_MIOLANIA_3, 'MOTHER_MIOLANIA'],
  [TetuGame.MONSTERS.HANGMANS_PATROL_F2P, 'HANGMANS_PATROL'],
  [TetuGame.MONSTERS.A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P, 'A_LITTLE_MORE_BANDITS_THAN_USUAL'],
  [TetuGame.MONSTERS.REBELLIOUS_PEASANT_F2P, 'REBELLIOUS_PEASANT'],
  [TetuGame.MONSTERS.DRUNK_CROSSBOWMAN_F2P, 'DRUNK_CROSSBOWMAN'],
  [TetuGame.MONSTERS.SOMBER_HANGMAN_F2P, 'SOMBER_HANGMAN'],
  [TetuGame.MONSTERS.FILTHY_WILLY_F2P, 'FILTHY_WILLY'],
  [TetuGame.MONSTERS.BARBARIAN_BITCH_10, 'BARBARIAN_BITCH'],
  [TetuGame.MONSTERS.BANISHED_PEASANT_10, 'BANISHED_PEASANT'],
  [TetuGame.MONSTERS.STARVING_WOLFS_10, 'STARVING_WOLFS'],
  [TetuGame.MONSTERS.PEDOPHILE_HUNTER_10, 'PEDOPHILE_HUNTER'],
  [TetuGame.MONSTERS.FRESH_INFECTED_DEADMAN_10, 'FRESH_INFECTED_DEADMAN'],
  [TetuGame.MONSTERS.STYLE_POLICE_10, 'STYLE_POLICE'],
  [TetuGame.MONSTERS.BAD_BEAR_10, 'BAD_BEAR'],
  [TetuGame.MONSTERS.ORDINARY_GOOSE_32, 'ORDINARY_GOOSE'],
  [TetuGame.MONSTERS.ORDINARY_GOOSE_34, 'ORDINARY_GOOSE'],
  [TetuGame.MONSTERS.LOCAL_DUDE_32, 'LOCAL_DUDE'],
  [TetuGame.MONSTERS.LOCAL_DUDE_34, 'LOCAL_DUDE'],
  [TetuGame.MONSTERS.HUNGRY_DOG_32, 'HUNGRY_DOG'],
  [TetuGame.MONSTERS.HUNGRY_DOG_34, 'HUNGRY_DOG'],
  [TetuGame.MONSTERS.ROWDY_10, 'ROWDY'],
  [TetuGame.MONSTERS.OLD_WHORE_10, 'OLD_WHORE'],
  [TetuGame.MONSTERS.TRAINING_BUDDY_33, 'TRAINING_BUDDY'],
  [TetuGame.MONSTERS.FEROCIOUS_WIFE_33, 'FEROCIOUS_WIFE'],
  [TetuGame.MONSTERS.FUGITIVE_KUDJA_33, 'FUGITIVE_KUDJA'],
  [TetuGame.MONSTERS.FUGITIVE_KUDJA_35, 'FUGITIVE_KUDJA'],
  [TetuGame.MONSTERS.SMELLY_CANNIBAL_34, 'SMELLY_CANNIBAL'],
  [TetuGame.MONSTERS.T_POSER_33, 'T_POSER'],
  [TetuGame.MONSTERS.T_POSER_35, 'T_POSER'],
  [TetuGame.MONSTERS.BAD_SEED_33, 'BAD_SEED'],
  [TetuGame.MONSTERS.BAD_SEED_35, 'BAD_SEED'],
  [TetuGame.MONSTERS.RIPE_MIDNIGHTER_2, 'RIPE_MIDNIGHTER'],
  [TetuGame.MONSTERS.JUICY_MIDNIGHTER_2, 'JUICY_MIDNIGHTER'],
  [TetuGame.MONSTERS.MERGULLIAN_HEADHUNTER_34, 'MERGULLIAN_HEADHUNTER'],
  [TetuGame.MONSTERS.SMALL_SPIDER_34, 'SMALL_SPIDER'],
  [TetuGame.MONSTERS.YOUNG_TROLL_35, 'YOUNG_TROLL'],
  [TetuGame.MONSTERS.FACELESS_10, 'FACELESS'],
  [TetuGame.MONSTERS.LONE_BARD_35, 'LONE_BARD'],
  [TetuGame.MONSTERS.FOREST_WITCH_35, 'FOREST_WITCH'],
  [TetuGame.MONSTERS.DARK_ADVENTURERS_35, 'DARK_ADVENTURERS'],
  [TetuGame.MONSTERS.SPICY_MIDNIGHTER_2, 'SPICY_MIDNIGHTER'],
  [TetuGame.MONSTERS.DARK_ESSENCE_35, 'DARK_ESSENCE'],
  [TetuGame.MONSTERS.STRABAN_KNIGHT_10, 'STRABAN_KNIGHT'],
  [TetuGame.MONSTERS.WOUNDED_DEMOLDER_10, 'WOUNDED_DEMOLDER'],
  [TetuGame.MONSTERS.HANGOVER_WILLY_10, 'HANGOVER_WILLY'],
  [TetuGame.MONSTERS.VENOMOUS_SPIDER_10, 'VENOMOUS_SPIDER'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_SCALD_10, 'DEMOLDER_CHAMPION_SCALD'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_THORN_10, 'DEMOLDER_CHAMPION_THORN'],
  [TetuGame.MONSTERS.DEMOLDER_CHAMPION_GEKK_10, 'DEMOLDER_CHAMPION_GEKK'],
  [TetuGame.MONSTERS.MIGHTY_RUIN_KEEPER_10, 'MIGHTY_RUIN_KEEPER'],
  [TetuGame.MONSTERS.KNIGHT_HAMMERGINA_10, 'KNIGHT_HAMMERGINA'],
  [TetuGame.MONSTERS.FULLMOON_DARK_ESSENCE_10, 'FULLMOON_DARK_ESSENCE'],
  [TetuGame.MONSTERS.SUPERGOOSE_10, 'SUPERGOOSE'],
]);

const DUNGEONS_ID = {
  100: 'BIOME_1_ALIVE_AGAIN_1_F2P',
  101: 'BIOME_1_ALIVE_AGAIN_1',
  102: 'BIOME_1_DEPTHS_OF_THE_FOREST_2',
  103: 'BIOME_1_LOF_BLAID_3',
  104: 'BIOME_1_OUTSKIRTS_OF_THE_CITY_4',
  105: 'BIOME_1_DESPERATION_5',
  106: 'BIOME_1_NIGHT_FOREST_6',
  107: 'BIOME_1_ANOTHER_DAY_7',
  108: 'BIOME_1_PILGRIMAGE_8',
  109: 'BIOME_1_THIS_IS_RIDICULOUS_9',
  110: 'BIOME_1_BLOOD_TEARS_10',
  111: 'BIOME_1_DANGEROUS_TRAILS_11',
  112: 'BIOME_1_GRIMLARNS_HOSPITALITY_12',
  113: 'BIOME_1_FORGOTTEN_LAND_13',
  114: 'BIOME_1_SACRA_HUNT_14',
  115: 'BIOME_1_IN_THE_WILD_15',
  116: 'BIOME_1_ABANDONED_TRACT_16',
  117: 'BIOME_1_TERRITORY_OF_BANDITS_17',
  118: 'BIOME_1_BURNED_VILLAGES_18',
  119: 'BIOME_1_HAMMERGINA_119',
  202: 'BIOME_2_DEPTHS_OF_THE_FOREST_2',
  203: 'BIOME_2_LOF_BLAID_3',
  204: 'BIOME_2_OUTSKIRTS_OF_THE_CITY_4',
  205: 'BIOME_2_DESPERATION_5',
  206: 'BIOME_2_NIGHT_FOREST_6',
  207: 'BIOME_2_ANOTHER_DAY_7',
  208: 'BIOME_2_PILGRIMAGE_8',
  209: 'BIOME_2_THIS_IS_RIDICULOUS_9',
  210: 'BIOME_2_BLOOD_TEARS_10',
  211: 'BIOME_2_DANGEROUS_TRAILS_11',
  212: 'BIOME_2_GRIMLARNS_HOSPITALITY_12',
  213: 'BIOME_2_FORGOTTEN_LAND_13',
  214: 'BIOME_2_SACRA_HUNT_14',
  216: 'BIOME_2_ABANDONED_TRACT_16',
  217: 'BIOME_2_TERRITORY_OF_BANDITS_17',
  218: 'BIOME_2_BURNED_VILLAGES_18',
  301: 'BIOME_3_WAYS_OF_THE_DROWNED',
  302: 'BIOME_3_TRAPS_ARE_EVERYWHERE',
  303: 'BIOME_3_FEED_THE_MOTHER',
  304: 'BIOME_3_GREAT_EXODUS',
  305: 'BIOME_3_LAST_SPARKS',
  306: 'BIOME_3_GROVE_OF_GIANTS',
  307: 'BIOME_3_CREEPY_PLACE',
  308: 'BIOME_3_WHAT_DOES_THE_THICK_FOG_HIDE',
  309: 'BIOME_3_ITS_GETTING_COLDER',
  310: 'BIOME_3_FORGOTTEN_FAITH',
  401: 'BIOME_4_LIFE_ROAD',
  402: 'BIOME_4_THREAT',
  403: 'BIOME_4_BONES',
  404: 'BIOME_4_ATTRACTIONS_OF_THE_ONALPHOE',
  405: 'BIOME_4_GLOW',
  406: 'BIOME_4_A_DAY_FULL_OF_HOPE',
  407: 'BIOME_4_CULT',
  408: 'BIOME_4_FORMER_GREATNESS',
  409: 'BIOME_4_SPIDER_SEASON',
  410: 'BIOME_4_UNPLEASANT_BUZZING',
};

// SHRINE
export const SHRINES: SpecificChambers = {
  BIOME_1_HEAL_SHRINE: genObjectId(1, 4, 1),
  BIOME_2_HEAL_SHRINE: genObjectId(2, 4, 2),
  BIOME_2_FIGHT_SHRINE: genObjectId(2, 4, 3),
  BIOME_2_LUCK_SHRINE: genObjectId(2, 4, 4),
  BIOME_2_MAGIC_SHRINE: genObjectId(2, 4, 5),
  BIOME_2_RESIST_SHRINE: genObjectId(2, 4, 6),
  BIOME_3_HEAL_SHRINE: genObjectId(3, 4, 7),
  BIOME_3_FIGHT_SHRINE: genObjectId(3, 4, 8),
  BIOME_3_LUCK_SHRINE: genObjectId(3, 4, 9),
  BIOME_3_MAGIC_SHRINE: genObjectId(3, 4, 10),
  BIOME_3_RESIST_SHRINE: genObjectId(3, 4, 11),
  BIOME_3_LC_SHRINE: genObjectId(3, 4, 12),
  BIOME_4_HEAL_SHRINE: genObjectId(4, 4, 13),
  BIOME_4_FIGHT_SHRINE: genObjectId(4, 4, 14),
  BIOME_4_LUCK_SHRINE: genObjectId(4, 4, 15),
  BIOME_4_MAGIC_SHRINE: genObjectId(4, 4, 16),
  BIOME_4_RESIST_SHRINE: genObjectId(4, 4, 17),
  BIOME_4_LC_SHRINE: genObjectId(4, 4, 18),
};

const SHRINE_INFO = new Map<number, MetaInfo>([
  [SHRINES.BIOME_1_HEAL_SHRINE, { name: 'Shrine', uri: geUriForName('BIOME_1_HEAL_SHRINE') }],
  [SHRINES.BIOME_2_HEAL_SHRINE, { name: 'Heal Shrine', uri: geUriForName('BIOME_2_HEAL_SHRINE') }],
  [SHRINES.BIOME_2_FIGHT_SHRINE, { name: 'Battle Shrine', uri: geUriForName('BIOME_2_FIGHT_SHRINE') }],
  [SHRINES.BIOME_2_LUCK_SHRINE, { name: 'Shrine of Luck', uri: geUriForName('BIOME_2_LUCK_SHRINE') }],
  [SHRINES.BIOME_2_MAGIC_SHRINE, { name: 'Magic Shrine', uri: geUriForName('BIOME_2_MAGIC_SHRINE') }],
  [SHRINES.BIOME_2_RESIST_SHRINE, { name: 'Resist Shrine', uri: geUriForName('BIOME_2_RESIST_SHRINE') }],
  [SHRINES.BIOME_3_HEAL_SHRINE, { name: 'Heal Shrine', uri: geUriForName('BIOME_2_HEAL_SHRINE') }],
  [SHRINES.BIOME_3_FIGHT_SHRINE, { name: 'Battle Shrine', uri: geUriForName('BIOME_2_FIGHT_SHRINE') }],
  [SHRINES.BIOME_3_LUCK_SHRINE, { name: 'Shrine of Luck', uri: geUriForName('BIOME_2_LUCK_SHRINE') }],
  [SHRINES.BIOME_3_MAGIC_SHRINE, { name: 'Magic Shrine', uri: geUriForName('BIOME_2_MAGIC_SHRINE') }],
  [SHRINES.BIOME_3_RESIST_SHRINE, { name: 'Resist Shrine', uri: geUriForName('BIOME_2_RESIST_SHRINE') }],
  [SHRINES.BIOME_3_LC_SHRINE, { name: 'Shrine of Chance', uri: geUriForName('BIOME_3_LC_SHRINE') }],
  [SHRINES.BIOME_4_HEAL_SHRINE, { name: 'Heal Shrine', uri: geUriForName('BIOME_2_HEAL_SHRINE') }],
  [SHRINES.BIOME_4_FIGHT_SHRINE, { name: 'Battle Shrine', uri: geUriForName('BIOME_2_FIGHT_SHRINE') }],
  [SHRINES.BIOME_4_LUCK_SHRINE, { name: 'Shrine of Luck', uri: geUriForName('BIOME_2_LUCK_SHRINE') }],
  [SHRINES.BIOME_4_MAGIC_SHRINE, { name: 'Magic Shrine', uri: geUriForName('BIOME_2_MAGIC_SHRINE') }],
  [SHRINES.BIOME_4_RESIST_SHRINE, { name: 'Resist Shrine', uri: geUriForName('BIOME_2_RESIST_SHRINE') }],
  [SHRINES.BIOME_4_LC_SHRINE, { name: 'Shrine of Chance', uri: geUriForName('BIOME_3_LC_SHRINE') }],
]);

const ITEMS_NAMES = new Map<string, string>([
  ['SACRA_CONS_6_ITEM', 'Dandelion Wine'],
  ['SACRA_RAGE_ITEM', 'Rage!'],
  ['SACRA_CONS_1_ITEM', 'Spoiled Berry Compote'],
  ['SACRA_OHW_9_ITEM', 'Rotten Page Sword'],
  ['SACRA_RING_2_ITEM', 'Spiked Ring'],
  ['SACRA_OFF_9_ITEM', 'Crystal Skull Orb'],
  ['SACRA_OHW_1_ITEM', 'Gaylords Rust Sword'],
  ['SACRA_CONS_20_ITEM', 'Violet Beer'],
  ['SACRA_RING_3_ITEM', 'Richly Inlaid Ring'],
  ['SACRA_SABBAH_POISON_ITEM', 'Sabbah Poison'],
  ['SACRA_STEP_BACK_ITEM', 'Step Back'],
  ['SACRA_HELM_4_ITEM', 'Deserter Helm'],
  ['SACRA_HELM_3_ITEM', 'Imperial Watchman Hood'],
  ['SACRA_REFLECTION_ITEM', 'Reflection'],
  ['SACRA_CONS_17_ITEM', 'Deer Blood'],
  ['SACRA_HEAT_STRIKE_ITEM', 'Heat Strike'],
  ['SACRA_GIANTS_BLOOD_ITEM', "Giant's Blood"],
  ['SACRA_CONS_19_ITEM', 'Sweet Poison'],
  ['SACRA_THW_7_ITEM', 'Corruptor Wand'],
  ['SACRA_OFF_6_ITEM', 'Poison Dagger'],
  ['SACRA_GLOVES_3_ITEM', 'Imperial Watchman Gloves'],
  ['SACRA_AMULET_7_ITEM', 'Deadhead'],
  ['SACRA_CONS_23_ITEM', 'Ghost Salt'],
  ['SACRA_CONS_18_ITEM', 'Sea Water'],
  ['SACRA_HELM_8_ITEM', 'Rust Deathmask'],
  ['SACRA_LAST_DANCE_ITEM', 'Last Dance'],
  ['SACRA_OHW_8_ITEM', 'Black Branch Wand'],
  ['SACRA_THW_5_ITEM', 'Cheap Myrdberg'],
  ['SACRA_CONS_2_ITEM', 'Spider Juice'],
  ['SACRA_JUSTICE_ITEM', 'Justice'],
  ['SACRA_GUIDING_LIGHT_ITEM', 'Guiding Light'],
  ['SACRA_TIME_AND_EXPERIENCE_ITEM', 'Time and Experience'],
  ['SACRA_BODY_3_ITEM', 'Imperial Watchman Armor'],
  ['SACRA_BEEF_PATTY_ITEM', 'Beef Patty'],
  ['SACRA_OFF_7_ITEM', 'Northtribe Shield'],
  ['SACRA_AMULET_11_ITEM', 'Flower of Champions'],
  ['SACRA_THW_3_ITEM', 'Ritual Hummer'],
  ['SACRA_ICE_SHARD_ITEM', 'Ice Shard'],
  ['SACRA_OFF_11_ITEM', 'Isaacs Nigidium'],
  ['SACRA_BODY_2_ITEM', 'Caitiff Jacket'],
  ['SACRA_I_SURVIVE_ITEM', "I'm Survive"],
  ['SACRA_GLOVES_8_ITEM', 'Rust Deathclaws'],
  ['SACRA_BODY_5_ITEM', 'Rust Heavy Plate'],
  ['SACRA_AMULET_6_ITEM', 'Demon Sign'],
  ['SACRA_CONS_10_ITEM', 'Blue Lagoon'],
  ['SACRA_AMULET_10_ITEM', 'Lucky Deer Horn'],
  ['SACRA_SPIT_IN_FACE_ITEM', 'Spit in Face'],
  ['SACRA_GUT_PUNCH_ITEM', 'Gut Punch!'],
  ['SACRA_OHW_4_ITEM', 'Bandit Axe'],
  ['SACRA_BELT_4_ITEM', "Officer's Belt"],
  ['SACRA_THW_4_ITEM', 'Great Northbye'],
  ['SACRA_RING_9_ITEM', 'Madmans Ring'],
  ['SACRA_RING_7_ITEM', "Garold's Ring"],
  ['SACRA_CONS_22_ITEM', 'Demon Seal'],
  ['SACRA_BODY_4_ITEM', 'Deserter Armor'],
  ['SACRA_HELM_5_ITEM', "Jagga's Cauldron"],
  ['SACRA_HELM_7_ITEM', 'Pirate Helmet'],
  ['SACRA_GLOVES_6_ITEM', 'Slaughterers Bracelets'],
  ['SACRA_MELT_ITEM', 'Melt'],
  ['SACRA_CONS_11_ITEM', 'Bumpkin Absinthe'],
  ['SACRA_CONS_21_ITEM', 'Chicken Thigh with Rosemary'],
  ['SACRA_THW_11_ITEM', 'Dawn Box'],
  ['SACRA_OHW_13_ITEM', 'Elegant Hammer'],
  ['SACRA_FEAR_ITEM', 'Fear'],
  ['SACRA_SCARLET_BRANCH_ADEPT_ITEM', 'Scarlet Branch Adept'],
  ['SACRA_PATIENCE_ITEM', 'Patience'],
  ['SACRA_OHW_12_ITEM', 'Prince Hobnail'],
  ['SACRA_CONS_12_ITEM', 'Snake Milkshake'],
  ['SACRA_CONS_9_ITEM', 'Fruit Tea'],
  ['SACRA_BOOTS_6_ITEM', 'Swamp Knight Boots'],
  ['SACRA_BOOTS_1_ITEM', 'Dumb Bandit Sandals'],
  ['SACRA_RING_4_ITEM', 'Hunter Ring'],
  ['SACRA_GLOVES_7_ITEM', 'Page Gloves'],
  ['SACRA_RING_10_ITEM', 'Serpent Skin'],
  ['SACRA_RING_8_ITEM', "Ancient Mage's Ring"],
  ['SACRA_HELM_6_ITEM', 'Witch Blindfold'],
  ['SACRA_OHW_2_ITEM', 'Rusty Cleaver'],
  ['SACRA_CONS_26_ITEM', 'Old Map'],
  ['SACRA_CONS_3_ITEM', 'Dose of Spinach'],
  ['SACRA_RING_1_ITEM', 'Wooden Ring'],
  ['SACRA_LIFEDRAIN_ITEM', 'Lifedrain'],
  ['SACRA_OFF_1_ITEM', 'Middle Finger'],
  ['SACRA_OHW_14_ITEM', 'Old Depilatorium'],
  ['SACRA_BOOTS_3_ITEM', 'Imperial Watchman Boots'],
  ['SACRA_THW_2_ITEM', 'Crap Iron Spear'],
  ['SACRA_AMULET_5_ITEM', 'Highlander Talisman'],
  ['SACRA_ANATHEMA_ITEM', 'Anathema'],
  ['SACRA_SAVAGE_CHARM_ITEM', 'Savage Charm!'],
  ['SACRA_AMULET_1_ITEM', 'Witchhand'],
  ['SACRA_AMULET_12_ITEM', 'Faded Pendant'],
  ['SACRA_ONSLAUGHT_ITEM', 'Onslaught'],
  ['SACRA_ARMOR_OF_FAITH_ITEM', 'Armor of Faith'],
  ['SACRA_BACKSTAB_ITEM', 'Backstab'],
  ['SACRA_CONS_14_ITEM', 'Octopus Blood'],
  ['SACRA_LIVE_FORTRESS_ITEM', 'Live Fortress'],
  ['SACRA_OFF_8_ITEM', 'Parry Dagger'],
  ['SACRA_CONS_15_ITEM', 'Horsecock Extract'],
  ['SACRA_STRONG_BLOCK_ITEM', 'Strong Block'],
  ['SACRA_GLOVES_5_ITEM', 'Assasin Graceful Bracelets'],
  ['SACRA_OFF_13_ITEM', 'Key to Nothing'],
  ['SACRA_ERASE_ITEM', 'Erase'],
  ['SACRA_GLOVES_4_ITEM', 'Deserter Gauntlets'],
  ['SACRA_BODY_1_ITEM', 'Dumb Bandit Swimsuit'],
  ['SACRA_BOOTS_4_ITEM', 'Deserter Greaves'],
  ['SACRA_CONS_5_ITEM', 'Good Berry Compote'],
  ['SACRA_GOLDEN_EYE_ITEM', 'Golden Eye'],
  ['SACRA_CONS_24_ITEM', 'Protective Decree'],
  ['SACRA_BRUTE_FORCE_ITEM', 'Brute Force'],
  ['SACRA_COLD_SHOULDER_ITEM', 'Cold Shoulder'],
  ['SACRA_CONS_8_ITEM', 'Eggplant Moonshine'],
  ['SACRA_OFF_10_ITEM', 'Big Pretty Fish'],
  ['SACRA_DEMON_SPEED_ITEM', 'Demon Speed'],
  ['SACRA_AMULET_4_ITEM', 'Security Talisman'],
  ['SACRA_OFF_2_ITEM', 'Creepy Idol'],
  ['SACRA_AMULET_9_ITEM', 'Old Symbol of Power'],
  ['SACRA_DEAD_STRIKE_ITEM', 'Dead Strike'],
  ['SACRA_AMULET_8_ITEM', 'Black Branch Amulet'],
  ['SACRA_AMULET_2_ITEM', 'Strange Rock'],
  ['SACRA_FIRM_HAND_ITEM', 'Firm Hand'],
  ['SACRA_BODY_7_ITEM', 'Human Skin Gown'],
  ['SACRA_HELM_1_ITEM', 'Bandit Crap Hat'],
  ['SACRA_THW_6_ITEM', 'Chief Axe'],
  ['SACRA_HELM_2_ITEM', 'Caitiff Hood'],
  ['SACRA_OHW_3_ITEM', 'Blind Mage Fence Part'],
  ['SACRA_OFF_3_ITEM', 'Teardrop Shield'],
  ['SACRA_CONS_25_ITEM', 'Cadaveric Honey'],
  ['SACRA_THUNDERBOLT_ITEM', 'Thunderbolt'],
  ['SACRA_OHW_5_ITEM', 'Scoundrels Dagger'],
  ['SACRA_BELT_1_ITEM', 'Piece of Deer Intestine'],
  ['SACRA_OFF_4_ITEM', 'Evil Deads Nigidium'],
  ['SACRA_AMULET_3_ITEM', 'Bravery Medal'],
  ['SACRA_STATIC_DISCHARGE_ITEM', 'Static Discharge'],
  ['SACRA_OFF_12_ITEM', 'Broken Key'],
  ['SACRA_VETERAN_CONFIDENCE_ITEM', 'Veteran Confidence'],
  ['SACRA_THW_10_ITEM', 'The Fencers Arguments'],
  ['SACRA_CONS_4_ITEM', 'Portion of Oatmeal'],
  ['SACRA_GLOVES_2_ITEM', 'Caitiff Gloves'],
  ['SACRA_CONS_7_ITEM', 'Cucumber Smoothie'],
  ['SACRA_RING_6_ITEM', 'Golden Plaque'],
  ['SACRA_THW_9_ITEM', 'Heavy Crossbow'],
  ['SACRA_BELT_3_ITEM', 'Street Dancer Belt'],
  ['SACRA_OHW_10_ITEM', 'Deviant Wand'],
  ['SACRA_BOOTS_5_ITEM', 'Fishing Boots'],
  ['SACRA_BOOTS_2_ITEM', 'Caitiff Boots'],
  ['SACRA_BELT_2_ITEM', 'Crappy Leather Belt'],
  ['SACRA_PARALYZE_ITEM', 'Paralyze'],
  ['SACRA_THW_8_ITEM', 'Infantry Bihander'],
  ['SACRA_BODY_6_ITEM', 'Interrogators Cloak'],
  ['SACRA_BOOTS_7_ITEM', 'Rust Deathboots'],
  ['SACRA_CONS_13_ITEM', 'Cherry Rom'],
  ['SACRA_OHW_11_ITEM', 'Solid Mace'],
  ['SACRA_FROSTBITE_ITEM', 'Frostbite'],
  ['SACRA_THW_1_ITEM', 'Wooden Skullcrusher'],
  ['SACRA_OHW_7_ITEM', 'Mergull Spata'],
  ['SACRA_OHW_6_ITEM', 'Imperial Scimitar'],
  ['SACRA_CONS_16_ITEM', "Old Lady's Sweat "],
  ['SACRA_LIKE_A_PUSSY_ITEM', 'Like a pussy!'],
  ['SACRA_OFF_5_ITEM', 'Fencer Buckler '],
  ['SACRA_STEEL_SKIN_ITEM', 'Steel Skin'],
  ['SACRA_GLOVES_1_ITEM', 'Dumb Bandit Mittens'],
  ['SACRA_RING_5_ITEM', 'Braided Serpent'],
  ['SACRA_CONS_27_ITEM', 'Burdock Leaf'],
  ['SACRA_CONS_28_ITEM', 'Pink Vine'],
  ['SACRA_CONS_29_ITEM', 'Dull Blood Tear'],
  ['SACRA_CONS_30_ITEM', 'Feeble Blood Tear'],
  ['SACRA_CONS_31_ITEM', 'Sharp Blood Tear'],
  ['SACRA_CONS_32_ITEM', 'Saturated Blood Tear'],
  ['SACRA_CONS_33_ITEM', "Watchman's Crystal Whistle"],
  ['SACRA_CONS_34_ITEM', 'Sacra Tree Twig'],
  ['SACRA_CONS_35_ITEM', 'Blessed Water'],
  ['SACRA_HELM_9_ITEM', 'Bone Glasses'],
  ['SACRA_BELT_5_ITEM', 'Ass Spanking Belt'],
  ['SACRA_AMULET_13_ITEM', 'Fancy Potato'],
  ['SACRA_BODY_8_ITEM', 'Nipple Piercing'],
  ['SACRA_RING_11_ITEM', 'Ice Song'],
  ['SACRA_OTHER_1_ITEM', "Watchman's Crystal Whistle"],
  ['SACRA_OTHER_2_ITEM', 'Wormhole Stone'],
  ['SACRA_OTHER_3_ITEM', 'Sacra Tree Twig'],
  ['SACRA_OTHER_4_ITEM', 'Magic Blue Tape'],
  ['SACRA_OTHER_5_ITEM', 'Shelter Key'],
]);

export function getMonsterImage(monsterId: number): string {
  const img = geUriForName(getMonsterNameForUri(monsterIdToName(monsterId)));
  if (!img) {
    throw new Error(`No image found for ${monsterId}`);
  }
  return img;
}

export function getMonsterName(monsterId: number): string {
  const name = MONSTER_NAMES.get(monsterId);
  if (!name) {
    throw new Error(`No name found for ${monsterId}`);
  }
  return name;
}

export function getMonsterSymbol(monsterId: number): string {
  const symbol = MONSTER_SYMBOLS.get(monsterId);
  if (!symbol) {
    throw new Error(`No symbol found for ${monsterId}`);
  }
  return symbol;
}

export function getDungeonInfoById(dungId: string): MetaInfo {
  const key = DUNGEONS_ID[dungId];
  if (key) {
    return {
      uri: geUriForName(getDungeonNameForUri(key)),
      name: DUNGEON_NAMES.get(key) || '',
    };
  }
  return {
    uri: '',
    name: '',
  };
}

export function getShelterUri(index: number): string {
  try {
    return geUriForName(`SHELTER_${index}`);
  } catch (e) {
    // default image
    return 'assets/images/ui/shelter/bg-image.avif';
  }
}

export function getEventInfoById(objectId: number): MetaInfo | undefined {
  return SHRINE_INFO.get(objectId);
}

export function getItemNameBySymbol(itemSymbol: string): string {
  return ITEMS_NAMES.get(itemSymbol) || itemSymbol;
}

export enum GuildsParams {
  NONE,
  COUNTER_GUILD_IDS,
  BASE_FEE,
  COUNTER_GUILD_REQUESTS,
  REENTRANT_STATUS,
  SHELTER_CONTROLLER,
  SHELTER_AUCTION,
}

export enum GuildRequestStatus {
  NONE,
  ACCEPTED,
  REJECTED,
  CANCELED,
}

export enum GuildRoles {
  HIGHLORD = 'Highlord',
  CHAMPION = 'Champion',
  GUARDIAN = 'Guardian',
  SCOUT = 'Scout',
}

export enum GuildRight {
  ADMIN = 1 << 0, // 2^0 = 1
  RENAME = 1 << 1, // 2^1 = 2
  CHANGE_LOGO = 1 << 2, // 2^2 = 4
  CHANGE_SHELTER = 1 << 3, // 2^3 = 8
  ADD_MEMBER = 1 << 4, // 2^4 = 16
  REMOVE_MEMBER = 1 << 5, // 2^5 = 32
  BANK_TOKENS_OPERATION = 1 << 6, // 2^6 = 64
  CHANGE_ROLES = 1 << 7, // 2^7 = 128
  LEVEL_UP = 1 << 8, // 2^8 = 256
  SET_RELATION_KIND = 1 << 9, // 2^9 = 512
  BANK_ITEMS_OPERATION = 1 << 10, // 2^10 = 1024
  SET_GUILD_PARAMS = 1 << 11, // 2^11 = 2048
  CHANGE_PURCHASING_SHELTER_ITEMS_CAPACITY = 1 << 12, // 2^12 = 4096
}

export const GuildRoleRights: { [key in GuildRoles]: number } = {
  [GuildRoles.HIGHLORD]:
    GuildRight.ADMIN |
    GuildRight.RENAME |
    GuildRight.CHANGE_LOGO |
    GuildRight.CHANGE_SHELTER |
    GuildRight.ADD_MEMBER |
    GuildRight.REMOVE_MEMBER |
    GuildRight.BANK_TOKENS_OPERATION |
    GuildRight.CHANGE_ROLES |
    GuildRight.LEVEL_UP |
    GuildRight.SET_RELATION_KIND |
    GuildRight.BANK_ITEMS_OPERATION |
    GuildRight.SET_GUILD_PARAMS |
    GuildRight.CHANGE_PURCHASING_SHELTER_ITEMS_CAPACITY,

  [GuildRoles.CHAMPION]:
    GuildRight.ADD_MEMBER |
    GuildRight.REMOVE_MEMBER |
    GuildRight.BANK_TOKENS_OPERATION |
    GuildRight.BANK_ITEMS_OPERATION,

  [GuildRoles.GUARDIAN]: GuildRight.ADD_MEMBER,

  [GuildRoles.SCOUT]: 0,
};

export function getRoleByRights(rights: number): GuildRoles {
  if (rights === 1) {
    return GuildRoles.HIGHLORD;
  }
  return (
    (Object.keys(GuildRoleRights) as Array<GuildRoles>).find(role => GuildRoleRights[role] === rights) ??
    GuildRoles.SCOUT
  );
}

export function hasRight(role: GuildRoles, right: GuildRight): boolean {
  return (GuildRoleRights[role] & right) !== 0;
}

const LEVEL_EXP = new Map<number, number>([
  [1, 5614],
  [2, 14128],
  [3, 25587],
  [4, 40034],
  [5, 57518],
  [6, 78086],
  [7, 101787],
  [8, 128672],
  [9, 158794],
  [10, 192205],
  [11, 228961],
  [12, 269119],
  [13, 312738],
  [14, 359879],
  [15, 410602],
  [16, 464974],
  [17, 523060],
  [18, 584929],
  [19, 650651],
  [20, 720299],
  [21, 793951],
  [22, 871682],
  [23, 953574],
  [24, 1039712],
  [25, 1130181],
  [26, 1225071],
  [27, 1324475],
  [28, 1428491],
  [29, 1537217],
  [30, 1650758],
  [31, 1769222],
  [32, 1892721],
  [33, 2021372],
  [34, 2155296],
  [35, 2294620],
  [36, 2439475],
  [37, 2590000],
  [38, 2746336],
  [39, 2908634],
  [40, 3077050],
  [41, 3251748],
  [42, 3432899],
  [43, 3620681],
  [44, 3815283],
  [45, 4016902],
  [46, 4225745],
  [47, 4442028],
  [48, 4665981],
  [49, 4897845],
  [50, 5137873],
  [51, 5386333],
  [52, 5643508],
  [53, 5909698],
  [54, 6185220],
  [55, 6470409],
  [56, 6765622],
  [57, 7071239],
  [58, 7387664],
  [59, 7715327],
  [60, 8054687],
  [61, 8406238],
  [62, 8770506],
  [63, 9148056],
  [64, 9539498],
  [65, 9945487],
  [66, 10366732],
  [67, 10803998],
  [68, 11258119],
  [69, 11730000],
  [70, 12220625],
  [71, 12731076],
  [72, 13262536],
  [73, 13816309],
  [74, 14393832],
  [75, 14996702],
  [76, 15626692],
  [77, 16285788],
  [78, 16976216],
  [79, 17700490],
  [80, 18461460],
  [81, 19262382],
  [82, 20106994],
  [83, 20999622],
  [84, 21945312],
  [85, 22950000],
  [86, 24020740],
  [87, 25166012],
  [88, 26396130],
  [89, 27723833],
  [90, 29165115],
  [91, 30740454],
  [92, 32476689],
  [93, 34410000],
  [94, 36590863],
  [95, 39092856],
  [96, 42029490],
  [97, 45590000],
  [98, 50127367],
]);

export function getExperienceRequiredForLevel(level: number): number {
  return LEVEL_EXP.get(level) ?? 0;
}

export enum HeroActionName {
  LEVEL_UP,
  EVENT_RESULT,
  CREATE,
  BOSS_COMPLETED,
  DUNGEON_COMPLETED,
  CLAIM_TOKEN,
  REBORN,
  CREATE_NGP,
  REINFORCEMENT_V2,
  REINFORCEMENT_GUILD,
  CREATE_NGP_SANDBOX,
}
