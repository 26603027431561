import { NgModule } from '@angular/core';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { environment } from '@environments/environment';
import { STORAGE_CUSTOM_SUBGRAPH_URL } from '@services/storage.service';
import { APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

const TETU_GAME_URI = environment.SUBGRAPH_URI;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export function createTetuGameSubgraph(httpLink: HttpLink) {
  const customUrl = localStorage.getItem(STORAGE_CUSTOM_SUBGRAPH_URL);
  let url = customUrl ? customUrl : TETU_GAME_URI;

  // change to default if using deprecated subgraph
  if(url.indexOf('node.sacrasubgraphftm41.zeeve.io') !== -1) {
    url = 'https://graph.tetu.io/subgraphs/name/sacra-fantom';
  }

  return {
    link: httpLink.create({ uri: url }),
    cache: new InMemoryCache({
      resultCaching: false,
    }),
    defaultOptions,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          GAME_SUBGRAPH: createTetuGameSubgraph(httpLink),
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
