export const environment = {
  production: true,
  RELAY_SERVER: "",
  STAGING: "false",
  WEB_3_AUTH_VERIFIER: "sacra-auth0-password",
  ALCHEMY_API_KEY: "yxS5_gkbzAQlklyOMxQtK91oHmeqThnm",
  WEB_3_AUTH_CLIENT_ID: "BN7MN4Y8Gs7B-PNdiB9E_xEgiDv5_tT_WcXdIe3JjT04VAdxuyN82Jd8uPswUv-zFmOfT0bcMzhCRN8Qfl6WRxQ",
  WALLET_CONNECT_PROJECT_ID: "55bfdffaf33a9b8d85648def6eccf20e",
  AUTH0_DOMAIN: "https://sacra.eu.auth0.com",
  PROXY_SUBGRAPH_SERVER_URL: "https://graph-proxy-fantom.sacra.game",
  RPC_PROVIDER_URL: "https://tetu-fantom-archive.zeeve.net/5tp65wx7r8mi0hoov1u9/rpc",
  OWRACLE_KEY: "faca0d3b59bb42e7a8522ed524b07349",
  FORCE_GRAPH_URL: "https://graph.tetu.io/subgraphs/name/sacra-sonic",
  AUTH0_CLIENT_ID: "mvzS5qEoIlwnRMNLNBcWnDWRx5nLhwo3",
  SUBGRAPH_URI: "https://graph.tetu.io/subgraphs/name/sacra-fantom",
  CHAIN_ID: "250",
  USE_PROXY_SUBGRAPH: "true",
  WAIT_FOR_SUBGRAPH: "0",
};
