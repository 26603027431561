import { AchievementModel } from '@models/achievement.model';
import { MediatorAction } from '@shared/types/action.interface';

export namespace AchievementsActions {
  export class Show implements MediatorAction {
    constructor(public achievement: AchievementModel) {}
  }

  export class Hide implements MediatorAction {}
}
