import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SignerOrProvider } from '@gelatonetwork/relay-sdk';
import { reloadPage } from '@helpers/common-helper';
import { blockExplorerLink } from '@helpers/web3-helper';
import { TransactionDataModel, TransactionStatus, TransactionStatusName } from '@models/transaction-data.model';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { ErrorService } from '@services/error.service';
import { GelatoRelayService } from '@services/gelato-relay.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { MetaMaskService } from '@services/meta-mask.service';
import { STORAGE_CUSTOM_RPC_URL, StorageService } from '@services/storage.service';
import { TransactionsService } from '@services/transactions.service';
import { Web3ModalService } from '@services/web3-modal.service';
import { Web3authService } from '@services/web3auth.service';
import { ActionStatusActions } from '@shared/actions/action-status.actions';
import { LoadingActions } from '@shared/actions/loading.actions';
import { DelegateDialogComponent, STORAGE_DELEGATE_HIDE } from '@shared/components/delegate-dialog/delegate-dialog';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import {
  DEFAULT_TRANSACTION_SPEED,
  STORAGE_CUSTOM_GAS_VALUE,
  STORAGE_TRANSACTION_SPEED,
} from '@shared/components/settings-dialog/settings-dialog.component';
import {
  STORAGE_SPEEDUP_HIDE,
  StorePrivateKeyDialogComponent,
} from '@shared/components/store-private-key-dialog/store-private-key-dialog';
import { CHAIN_FIELDS, CHAIN_IDS, CHAIN_TYPE, CHAINS, getChainByChainId } from '@shared/constants/chain-ids.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { STORAGE_KEY_SPONSORED_TX } from '@shared/constants/settings.constants';
import { Alchemy, AlchemyEventType, AlchemySubscription, Network } from 'alchemy-sdk';
import { ethers, formatUnits, parseUnits, Signer } from 'ethers';
import { GasPriceOracle, GasPriceWithEstimate } from 'gas-price-oracle';
import { NGXLogger } from 'ngx-logger';
import {
  catchError,
  combineLatest,
  concatMap,
  filter,
  finalize,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  repeat,
  retry,
  Subject,
  switchMap,
  takeUntil,
  tap,
  take,
} from 'rxjs';
import Web3 from 'web3';

export type OWLRACLE_DATA = {
  speeds: {
    acceptance: number;
    baseFee: number;
    estimatedFee: number;
    maxFeePerGas: number;
    maxPriorityFeePerGas: number;
  }[];
};

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  expectedChain: CHAIN_TYPE;

  transactionQueue = new Map<string, TransactionDataModel>();
  ongoingTransaction = new Subject<TransactionDataModel>();
  transactionEnded = new Subject<TransactionDataModel>();
  chainId = 0;
  account = 'NO_ACCOUNT';
  chainChanged$: Subject<number> = new Subject<number>();
  accountChanged$: Subject<string> = new Subject<string>();

  private lastProvider?: any;

  txStartToSign = false;
  gasTooLowCount = 0;
  sponsoredTransactionsEnabled = true;
  private speedUpDialogOpenCounter = 0;
  private speedUpDialogOpened = false;
  delegateDialogOpened = false;
  public feeData?: {
    maxFeePerGas?: bigint;
    maxPriorityFeePerGas?: bigint;
    gasPrice?: bigint;
  };
  private isOwracleError = false;
  private oracle = new GasPriceOracle({
    defaultRpc: localStorage.getItem(STORAGE_CUSTOM_RPC_URL)
      ? localStorage.getItem(STORAGE_CUSTOM_RPC_URL) || ''
      : environment['RPC_PROVIDER_URL'],
    chainId: Number(environment['CHAIN_ID'] ?? 0),
  });

  private closurePK(pk: string) {
    const _pk = pk;
    return function () {
      return _pk;
    };
  }

  private closurePKDelegated(pk: string) {
    const _pk = pk;
    return function () {
      return _pk;
    };
  }

  private privateKey?: () => string;
  private privateKeyDelegated?: () => string;

  private signers = new Map<string, ethers.JsonRpcSigner>();

  constructor(
    private metaMaskService: MetaMaskService,
    private logger: NGXLogger,
    private mediator: Mediator,
    private transactionsService: TransactionsService,
    private web3ModalService: Web3ModalService,
    private subgraphService: SubgraphService,
    private errorService: ErrorService,
    private gelatoRelayService: GelatoRelayService,
    private storageService: StorageService,
    private destroy$: DestroyService,
    private translateService: TranslateService,
    private dialog: Dialog,
    private web3authService: Web3authService,
  ) {
    this.expectedChain = getChainByChainId(Number(environment.CHAIN_ID ?? '-1'));
    web3ModalService.provider$.pipe(takeUntil(this.destroy$)).subscribe(p => {
      this.lastProvider = p;
      this.subscribeOnProvider(p);
    });

    if (this.storageService.get(STORAGE_KEY_SPONSORED_TX)) {
      const val = this.storageService.get(STORAGE_KEY_SPONSORED_TX) as CHECKBOX_STATE;
      this.sponsoredTransactionsEnabled = val === CHECKBOX_STATE.CHECKED;
    }
  }

  setupPrivateKey(privateKey: string) {
    this.privateKey = this.closurePK(privateKey);
  }

  setupPrivateKeyDelegated(privateKey: string) {
    this.privateKeyDelegated = this.closurePKDelegated(privateKey);
  }

  isLocalPK() {
    return !!this.privateKey || !!this.privateKeyDelegated;
  }

  getLocalPrivateKey() {
    return this.privateKey ? this.privateKey() : this.privateKeyDelegated ? this.privateKeyDelegated() : null;
  }

  clearAllPk() {
    this.privateKey = undefined;
    this.privateKeyDelegated = undefined;
  }

  subscribeOnTxProcessing(
    destroy$: DestroyService,
    changeDetectorRef: ChangeDetectorRef,
    callback: (isOngoing: boolean) => void,
  ) {
    this.ongoingTransaction.pipe(takeUntil(destroy$)).subscribe(data => {
      callback(data.status !== TransactionStatus.COMPLETED && data.status !== TransactionStatus.FAILED);
      changeDetectorRef.detectChanges();
    });
  }

  getSubgraphDelay() {
    // if (this.chainId === CHAIN_IDS.SEPOLIA) {
    //   return 1;
    // }
    // we can not garantee that subgraph will have synced data for last block
    // 1 block delay is assumption that graph node will have enough time for sync prev block data
    // probably if we will find a way to forbid sync next block if prev data unsynced we can set zero delay
    return Number(environment.WAIT_FOR_SUBGRAPH ?? '0');
  }

  getProviderForRead(signer?: string) {
    if (!signer || signer === '') {
      signer = this.account;
    }
    let rpcUrl = environment['RPC_PROVIDER_URL'];
    if (this.storageService.get(STORAGE_CUSTOM_RPC_URL)) {
      rpcUrl = this.storageService.get(STORAGE_CUSTOM_RPC_URL);
    }
    const chainInfo = getChainByChainId(Number(environment['CHAIN_ID']));

    const provider = new ethers.JsonRpcProvider(rpcUrl, chainInfo[CHAIN_FIELDS.ID], {
      // polling?: boolean;
      // staticNetwork?: null | Network;
      // batchStallTime?: number;
      // batchMaxSize?: number;
      // batchMaxCount?: number;
      // cacheTimeout?: number;
      // pollingInterval?: number;
    });
    // this.logger.trace('provider for read use account', signer);
    if (!signer || signer === '' || signer === 'NO_ACCOUNT' || signer === '0') {
      return provider;
    } else {
      return new ethers.JsonRpcSigner(provider, signer);
    }
  }

  getProviderForWrite$() {
    if (this.lastProvider) {
      return of(new ethers.BrowserProvider(this.lastProvider, 'any'));
    } else {
      return this.web3ModalService.provider$.pipe(
        map(p => {
          return new ethers.BrowserProvider(p, 'any');
        }),
        // takeUntil(this.destroy$),
      );
    }
  }

  getSignerForWrite$(account: string, isDelegatedRelayPossible: boolean): Observable<Signer> {
    console.log('get Signer ForWrite$', isDelegatedRelayPossible, !!this.privateKeyDelegated);
    // it need to speedup signing process via web3auth
    if (this.privateKey) {
      return this.getProviderForWrite$().pipe(
        mergeMap(p => {
          return of(new ethers.Wallet(this.privateKey ? this.privateKey() : '', p));
        }),
      );
    } else if (isDelegatedRelayPossible && !!this.privateKeyDelegated) {
      return this.getProviderForWrite$().pipe(
        mergeMap(p => {
          return of(new ethers.Wallet(this.privateKeyDelegated ? this.privateKeyDelegated() : '', p));
        }),
      );
    } else {
      return this.getProviderForWrite$().pipe(
        mergeMap(p => {
          return p.getSigner(account);
        }),
      );
    }
  }

  getWeb3$(): Observable<Web3> {
    if (this.lastProvider) {
      return of(new Web3(this.lastProvider));
    } else {
      return this.web3ModalService.provider$.pipe(
        map(p => {
          return new Web3(p);
        }),
        takeUntil(this.destroy$),
      );
    }
  }

  accounts$() {
    return merge(this.accountChanged$, this.providerAccounts$()).pipe(filter(([acc]) => !!acc));
  }

  subscribeOnChainId$() {
    return merge(
      this.chainChanged$,
      this.getWeb3$().pipe(
        mergeMap(web3 => {
          return from(web3.eth.getChainId());
        }),
      ),
    );
  }

  subscribeOnAccountAndNetwork(
    destroy$: DestroyService,
    ref: ChangeDetectorRef,
    accountConsumer: (x: string) => void,
    chainIdConsumer: (x: number) => void,
  ) {
    this.accounts$()
      .pipe(
        switchMap(acc => {
          return forkJoin([
            of(acc),
            // refresh last block from subgraph
            this.subgraphService.graphData$(),
          ]);
        }),
        takeUntil(destroy$),
      )
      .subscribe(([[account]]) => {
        this.account = account;
        accountConsumer(account);
        ref.detectChanges();
      });
    this.subscribeOnChainId$()
      .pipe(takeUntil(destroy$))
      .subscribe(chainId => {
        this.chainId = chainId;
        this.transactionsService.checkLastTx(this);
        chainIdConsumer(chainId);
        ref.detectChanges();
      });
  }

  private providerAccounts$() {
    return this.getProviderForWrite$().pipe(
      mergeMap(p => {
        return p.listAccounts();
      }),
      tap(signers => signers.forEach(signer => this.signers.set(signer.address, signer))),
      map(p => p.map(x => x.address)),
    );
  }

  subscribeOnProvider(provider: any) {
    this.logger.trace('Subscribe on provider', provider);
    // @ts-ignore
    provider.on('accountsChanged', (accounts: string[]) => {
      if (accounts[0]) {
        this.accountChanged$.next(accounts[0]);
      } else {
        // undefined account means sometihng is reseted in metamask, need to reload everything
        this.reloadPageOnAccOrNetChange();
      }
    });
    // @ts-ignore
    provider.on('chainChanged', (chainId: number) => {
      this.logger.trace('Chain changed', chainId);
      this.chainChanged$.next(chainId);
    });
    // @ts-ignore
    provider.on('connect', (connectInfo: ConnectInfo) => {
      this.logger.trace('Connected', connectInfo);
      // this.accountChanged$.next(accounts[0]);
    });
    // @ts-ignore
    provider.on('disconnect', () => {
      this.logger.trace('Metamask Disconnected!');
      this.reloadPageOnAccOrNetChange();
    });
    // @ts-ignore
    provider.on('message', (message: ProviderMessage) => {
      this.logger.trace('>>>> METAMASK MESSAGE!', message);
    });
  }

  isSponsoredRelayPossible(data: TransactionDataModel) {
    if (!data.isSponsoredRelayPossible) {
      return of(false);
    }
    const relayDisabledForChain =
      this.chainId === CHAIN_IDS.SONIC ||
      this.chainId === CHAIN_IDS.FANTOM ||
      this.chainId === CHAIN_IDS.REAL ||
      this.chainId === CHAIN_IDS.NEBULA_TESTNET;
    // no relay for some chains
    if (relayDisabledForChain) {
      return of(false);
    }

    return from(this.getProviderForRead().provider.getBalance(this.account)).pipe(
      switchMap(balance => {
        if (balance === 0n) {
          this.showNotEnoughTokensInfo();
          throw new Error(
            'Your balance is empty and we can not subsidize your transaction. Please top up your balance.',
          );
        }

        return this.gelatoRelayService.getRelayLimit$(this.chainId.toString(), this.account).pipe(
          switchMap(limit => {
            this.logger.trace('Relay limit', limit, balance);

            const isPossibleByDefault = data.isSponsoredRelayPossible; // this.chainId !== CHAIN_IDS.SEPOLIA ||

            const isPossible =
              this.sponsoredTransactionsEnabled &&
              isPossibleByDefault &&
              limit.remaining > 0 &&
              limit.globalRemaining > 0;

            if (!isPossible && balance === 0n) {
              this.showNotEnoughTokensInfo();

              throw new Error(
                'Your balance is empty and we can not subsidize your transaction. Please top up your balance.',
              );
            }

            return of(isPossible);
          }),
        );
      }),
    );
  }

  isDelegatedRelayPossible(data: TransactionDataModel): Observable<boolean> {
    if (!data.isDelegatedRelayPossible) {
      return of(false);
    }

    const delegator = this.getDelegatorAddress() ?? '';

    if (delegator === '') {
      return of(false);
    }

    return from(this.getProviderForRead().provider.getBalance(delegator)).pipe(
      mergeMap(balance => {
        const minBal = (getChainByChainId(this.chainId)[CHAIN_FIELDS.COINS_FOR_DELEGATION] ?? 1) / 100;
        return of(+formatUnits(balance) > minBal);
      }),
    );
  }

  getDelegatorAddress() {
    if (!this.privateKeyDelegated) {
      return undefined;
    }
    return new ethers.Wallet(this.privateKeyDelegated ? this.privateKeyDelegated() : '').address;
  }

  onChainCall(data: TransactionDataModel) {
    this.logger.trace('>>> START ON CHAIN CALL', data);

    if (data.showSpeedUpDialog) {
      // this.showSpeedUpDialog();
    }

    this.txStartToSign = true;
    this.transactionQueue.set(data.id, data);
    this.ongoingTransaction.next(data);
    this.transactionsService.setTransaction(data);

    data.chainId = this.chainId;

    if (data.showLoadingScreen) {
      this.mediator.dispatch(new LoadingActions.Toggle(true, data));
    }

    if (!data.txPopulated) {
      throw new Error('Call is not defined');
    }

    this.dispatchAction(data);

    return this.isSponsoredRelayPossible(data).pipe(
      switchMap(isSponsoredRelayPossible => {
        if (isSponsoredRelayPossible) {
          return this.handleCall(this.sponsoredRelayCall(data), data);
        } else {
          return this.isDelegatedRelayPossible(data).pipe(
            switchMap(isDelegatedRelayPossible => {
              if (isDelegatedRelayPossible) {
                this.logger.trace('Delegated relay call');
                return this.handleCall(this.delegatedRelayCall(data), data);
              } else {
                return this.handleCall(this.normalOnChainCall(data, isDelegatedRelayPossible), data);
              }
            }),
          );
        }
      }),
      tap(([r, n]) => {
        if (r && r.status !== TransactionStatus.COMPLETED) {
          this.logger.error('Transaction failed', n, r);
          throw new Error(data.name + ': Transaction failed. Tx hash: ' + r.hash);
        }
      }),
      finalize(() => {
        this.logger.info('FINALIZE transaction', data);
        this.txStartToSign = false;
        this.transactionEnded.next(data);
        this.ongoingTransaction.next(data);
        this.dispatchAction(data);
        this.transactionsService.setTransaction(data);
        // if (this.showLoadingScreen) {
        this.mediator.dispatch(new LoadingActions.Toggle(false, data));
        // }
      }),
      catchError(err => {
        if (JSON.stringify(err).includes('insufficient funds') && !!this.privateKeyDelegated) {
          throw this.errorService.onCatchError('Delegator balance is not enough.');
        }
        if (JSON.stringify(err).includes('transaction underpriced')) {
          this.gasTooLowCount++;
        }
        if (JSON.stringify(err).includes('user rejected action')) {
          throw err;
        }

        this.logger.error('Catch call error', err);
        if (err && err.code === 4001) {
          data.status = TransactionStatus.REJECTED;
          data.error = err.message;
        } else {
          data.status = TransactionStatus.FAILED;
          data.error = err.reason;
        }
        throw this.errorService.onCatchError(err);
      }),
    );
  }

  sponsoredRelayCall(data: TransactionDataModel): Observable<string> {
    return forkJoin([from(data.txPopulated), this.getSignerForWrite$(this.account, false)]).pipe(
      switchMap(([txData, provider]) => {
        data.to = txData.to;
        return this.gelatoRelayService.getSignatureDataERC2771$(
          provider as unknown as SignerOrProvider,
          this.chainId,
          txData.to,
          txData.data,
          this.account,
        );
      }),
      switchMap(sigData => {
        return this.gelatoRelayService
          .sponsoredCall$(
            sigData.struct.chainId.toString(),
            sigData.struct.user,
            sigData.struct.target,
            sigData.struct.data.toString(),
            sigData.struct['userNonce'].toString(),
            sigData.struct.userDeadline.toString(),
            sigData.signature,
            data.gasLimit?.toString() ?? '0',
          )
          .pipe(
            switchMap(taskId => {
              return this.gelatoRelayService.waitForTaskResult$(taskId.taskId, data);
            }),
            map(res => {
              if (res.txHash) {
                return res;
              } else {
                throw new Error(res.error);
              }
            }),
            switchMap(res => {
              return this.gelatoRelayService.getTaskIdResult$(res.taskId);
            }),
            map(res => {
              this.logger.trace('Relay API result', res);
              if (res.task.transactionHash) {
                return res.task.transactionHash;
              } else {
                throw new Error(res.task.lastCheckMessage ?? 'Unknown sponsored tx error');
              }
            }),
          );
      }),
      catchError(err => {
        if (err.status === 429) {
          this.logger.warn('Relay capacity reached!');
          data.isSponsoredRelayPossible = false;
          return this.normalOnChainCall(data, false);
        } else {
          throw err;
        }
      }),
    );
  }

  normalOnChainCall(data: TransactionDataModel, isDelegatedRelayPossible: boolean): Observable<string> {
    return from(data.txPopulated).pipe(
      switchMap(tx => {
        return this.getSignerForWrite$(data.from ?? this.account, isDelegatedRelayPossible || data.useDelegatedPK).pipe(
          switchMap(signer => {
            if (data.value) {
              tx.value = data.value;
            }
            tx.gasLimit = data.gasLimit;
            tx.maxFeePerGas = data.maxFeePerGas;
            tx.maxPriorityFeePerGas = data.maxPriorityFeePerGas;
            tx.gasPrice = data.gasPrice;
            this.logger.trace('send tx', tx);
            return from(signer.sendTransaction(tx));
          }),
        );
      }),
      tap(x => this.logger.trace('normal onchain call result', x)),
      map(x => x.hash),
    );
  }

  delegatedRelayCall(data: TransactionDataModel): Observable<string> {
    const delegator = new ethers.Wallet(this.privateKeyDelegated ? this.privateKeyDelegated() : '').address;
    if (!data.relayService) {
      throw new Error('Relay service is not defined');
    }
    return forkJoin([
      data.relayService.createDelegatedTx(data, this.account, delegator),
      this.getAccountNonce(delegator),
    ]).pipe(
      switchMap(([tx, nonce]) => {
        return this.getSignerForWrite$(this.account, data.isDelegatedRelayPossible).pipe(
          switchMap(signer => {
            tx.value = data.value;
            tx.gasLimit = data.gasLimit;
            tx.maxFeePerGas = data.maxFeePerGas;
            tx.maxPriorityFeePerGas = data.maxPriorityFeePerGas;
            tx.gasPrice = data.gasPrice;
            tx.nonce = nonce;
            return signer.sendTransaction(tx);
          }),
        );
      }),
      map(x => x.hash),
    );
  }

  private handleCall(call: Observable<string>, data: TransactionDataModel) {
    return call.pipe(
      switchMap(hash => {
        this.txStartToSign = false;
        this.logger.trace({ msg: `${data.name} tx hash: ${hash}` });

        data.tx = hash;
        data.txLink = blockExplorerLink(this.chainId) + '/tx/' + hash;

        this.transactionsService.setTransaction(data);

        // const wsMessages = new Subject<ethers.TransactionReceipt | null>();
        // we should have a wrapper for keep object link for retries
        let receivedReceipt: { r: ethers.TransactionReceipt | null } = { r: null };

        // this pipe has dedicated retry for do not call subgraph until we are waiting for receipt
        // subgraph will be never synced if we call it before receipt
        const receiptPipe = forkJoin([
          of(receivedReceipt).pipe(
            switchMap(receipt => {
              if (receipt.r === null) {
                return this.waitForTransaction(hash, 0); // data.waitViaWs ? 0 : 1);
              } else {
                return of(receipt.r);
              }
            }),
          ),
          of(receivedReceipt).pipe(
            switchMap(receipt => {
              if (receipt.r === null) {
                if (!data.waitViaWs) {
                  return of(null);
                } else {
                  return this.waitForTransactionFromWs(hash);
                }
              } else {
                return of(receipt.r);
              }
            }),
          ),
        ]).pipe(
          repeat({ count: 999, delay: 1000 }),
          map(([receipt, ws]) => {
            receivedReceipt.r = receipt ? receipt : ws;
            return receivedReceipt.r;
          }),
          filter(receipt => {
            this.logger.trace('receipt check', receipt?.blockNumber);
            return !!receipt;
          }),
          take(1),
          retry({ count: 10, delay: 1000 }),
        );

        // at this point we have receipt and will retry only subgraph
        // receipt pipe will return cached value
        return combineLatest([
          receiptPipe,
          data.waitSubgraphSync
            ? data.subgraphWaitUserData
              ? this.subgraphService.userLastActionBlock$(this.account)
              : this.subgraphService.graphData$().pipe(map(d => d?.block?.number ?? 0))
            : of(999_999_999_999),
        ]).pipe(
          repeat({ count: 999, delay: 1000 }),
          filter(([receipt, lastBlock]) => {
            const isValid = this.isValidReceipt(receipt, lastBlock);
            // this.logger.trace('Repeat subgraph and receipt check', isValid, receipt?.blockNumber, lastBlock)
            return isValid;
          }),
          take(1),
        );
      }),
      tap(([receipt]) => {
        this.gasTooLowCount = 0;
        this.logger.trace(`RESPONSE ${data.name} tx:`, receipt);
        if (receipt !== null && receipt.status === 1) {
          data.status = TransactionStatus.COMPLETED;
        } else {
          data.status = TransactionStatus.FAILED;
        }
      }),
    );
  }

  // return false if receipt is empty or if lastBlockFromSubgraph lower that block from tx
  isValidReceipt(receipt: ethers.TransactionReceipt | null, lastBlockFromSubgraph: number) {
    let txBlock = 0;
    if (receipt === null) {
      this.logger.trace('Do not have receipt for tx');
      return false;
    } else {
      txBlock = receipt.blockNumber;
    }
    const res = txBlock <= lastBlockFromSubgraph - this.getSubgraphDelay();
    if (!res) {
      this.logger.trace('subgraph unsynced on ', txBlock - lastBlockFromSubgraph - this.getSubgraphDelay());
    }
    return res;
  }

  private dispatchAction(data: TransactionDataModel) {
    this.mediator.dispatch(
      new ActionStatusActions.ShowAction(data.status, TransactionStatusName[data.status], data.name, data.txLink),
    );
  }

  changeNetworkAndRedirect(chainIdNumber: number, destroy$: DestroyService, callBack: (result: number) => void) {
    this.logger.trace('Change network', chainIdNumber, this.web3ModalService.cachedProvider);

    // if (this.web3ModalService.cachedProvider === 'injected') {
    const chainId = '0x' + chainIdNumber.toString(16);
    this.metaMaskService
      .changeNetwork$(chainId)
      .pipe(
        switchMap(x => {
          const code = !!x?.data?.originalError ? x?.data?.originalError?.code : !!x ? x?.code : -1;
          if (code === 4902) {
            this.logger.trace('Network does not exist in MM', x);
            return this.metaMaskService.addEthereumChain$(chainId);
          } else {
            return of(code);
          }
        }),
        takeUntil(destroy$),
      )
      .subscribe(code => {
        this.logger.trace('Network change result', code);

        if (this.expectedChain.id !== chainIdNumber) {
          this.redirect(chainIdNumber);
        }
        callBack(code);
      });
  }

  redirect(chainIdNumber: number) {
    const url = CHAINS.get(chainIdNumber)?.DAPP_URL;
    if (!window.location.href.includes('localhost') && url) {
      reloadPage(url);
    }
  }

  getAlchemyNetwork() {
    switch (this.chainId) {
      case 137:
        return Network.MATIC_MAINNET;
      case 80001:
        return Network.MATIC_MUMBAI;
      case 11155111:
        return Network.ETH_SEPOLIA;
    }
    return undefined;
  }

  waitForTransaction(hash: string, confirms: number) {
    this.logger.trace('wait tx via provider', hash);
    return from(this.getProviderForRead().provider.waitForTransaction(hash, confirms));
  }

  waitForTransactionFromWs(hash: string): Observable<ethers.TransactionReceipt | null> {
    const net = this.getAlchemyNetwork();
    if (!net) {
      of(null);
    }
    const waiter = new Subject<ethers.TransactionReceipt | null>();

    this.waitForTransaction(hash, 0)
      .pipe(
        tap(x => {
          if (!!x) {
            sent = true;
            waiter.next(x);
            waiter.complete();
            if (p) {
              p.ws.removeAllListeners();
            }
          }
        }),
        takeUntil(waiter),
      )
      .subscribe();

    // const adrInfo = data.to ? { to: data.to } : { from: this.account };
    const event: AlchemyEventType = {
      method: AlchemySubscription.MINED_TRANSACTIONS,
      // addresses: [adrInfo],
      includeRemoved: true,
      hashesOnly: true,
    };

    let sent = false;
    let p: Alchemy | undefined;
    const apiKey = environment?.ALCHEMY_API_KEY ? environment.ALCHEMY_API_KEY : 'demo';
    try {
      p = new Alchemy({
        apiKey: apiKey,
        network: net,
        maxRetries: 0,
      });
      this.logger.trace('subscribe on ws for waiting tx', hash);
      p.ws.on(event, message => {
        sent = true;
        // this.logger.trace('web socket message', message);
        if (message && message.transaction) {
          const receivedHash = message.transaction.hash ?? '';
          // const block = message.transaction.blockNumber ?? '0x0';
          if (receivedHash.toString().toLowerCase() === hash.toLowerCase()) {
            this.logger.trace('ws received correct hash');
            // waiter.next(Number(BigInt(block)));
            from(this.getProviderForRead().provider.getTransactionReceipt(receivedHash))
              .pipe
              // takeUntil(repeatNotifier),
              ()
              .subscribe(x => {
                if (x) {
                  // this.logger.trace('ws correct receipt', x);
                  waiter.next(x);
                  waiter.complete();
                  if (p) {
                    p.ws.removeAllListeners();
                  }
                } else {
                  this.logger.trace('ws incorrect receipt');
                  waiter.next(null);
                }
              });
          } else {
            // waiter.next(0);
          }
        }
      });
    } catch (e) {
      console.error('ws error', e);
      waiter.next(null);
      waiter.complete();
      if (p) {
        p.ws.removeAllListeners();
      }
    }

    this.waitForTransaction(hash, 0)
      .pipe(
        tap(x => {
          if (!!x) {
            sent = true;
            waiter.next(x);
            waiter.complete();
            if (p) {
              p.ws.removeAllListeners();
            }
          }
        }),
        takeUntil(waiter),
      )
      .subscribe();

    // in case if something wrong with ws send an empty msg
    setTimeout(
      async () => {
        if (!sent) {
          this.logger.trace('did not receive msg from ws, send empty');
          waiter.next(null);
          waiter.complete();
          if (p) {
            p.ws.removeAllListeners();
          }
        }
      },
      apiKey === 'demo' ? 10_000 : 100_000,
    );

    return waiter;
  }

  getAccountNonce(account: string) {
    return from(this.getProviderForRead().provider.getTransactionCount(account));
  }

  parseLogs(contract: string, topics: string[], end: number | null = null, step = 3000) {
    return of(this.getProviderForRead().provider).pipe(
      concatMap(p => {
        return from(p.getBlockNumber()).pipe(
          concatMap(block => {
            if (end === null) {
              end = block;
            }
            const start = end - step;

            return from(
              p.getLogs({
                fromBlock: start,
                toBlock: end,
                address: contract,
                topics: topics,
              }),
            );
          }),
        );
      }),
    );
  }

  private getFeesFromOwracle(): Observable<OWLRACLE_DATA> {
    let net = '';
    if (Number(environment['CHAIN_ID'] ?? 0) === CHAIN_IDS.FANTOM) {
      net = 'ftm';
    }
    if (net === '' || this.isOwracleError) {
      return of({ speeds: [] });
    }

    const key = environment['OWRACLE_KEY'] ?? 'eac0890154484d38be40755ada9f00f7'; // tmp key fbbd346a8066450b8f1ba6094d3bd95a
    return from(fetch(`https://api.owlracle.info/v4/${net}/gas?apikey=${key}`)).pipe(
      switchMap(res => res.json()),
      catchError((err, res) => {
        if (err) {
          this.isOwracleError = true;
          return of({ speeds: [] });
        }
        return res;
      }),
    );
  }

  getActualFees() {
    const chainId = Number(environment['CHAIN_ID'] ?? 0);
    if (chainId === CHAIN_IDS.NEBULA_TESTNET) {
      this.feeData = {
        gasPrice: parseUnits('0.0001', 9),
      };
      return of(this.feeData);
    }

    return forkJoin([
      from(this.oracle.gasPricesWithEstimate()),
      this.getFeesFromOwracle(),
      chainId === CHAIN_IDS.FANTOM
        ? this.getProviderForRead().provider.send('eth_effectiveBaseFee', [])
        : of(undefined),
    ]).pipe(
      map(([oracleFee, owracle, providerFee]) => {
        console.log('eth_effectiveBaseFee', providerFee ? BigInt(providerFee) : 'not fantom');
        const effectiveGas = providerFee
          ? (BigInt(providerFee) * (105n + BigInt(this.gasTooLowCount) * 5n)) / 100n
          : undefined;
        const f = this.adjustFeeData(owracle, oracleFee, effectiveGas);
        console.log('fee data', owracle, oracleFee, f, effectiveGas);
        this.feeData = {
          maxFeePerGas: f.maxFeePerGas,
          maxPriorityFeePerGas: f.maxPriorityFeePerGas,
          gasPrice: f.gasPrice,
        };
        return this.feeData;
      }),
    );
  }

  private adjustFeeData(owracle: OWLRACLE_DATA, fee: GasPriceWithEstimate, effectiveBaseFee: bigint | undefined) {
    const speedIdx = Number(
      JSON.parse(window.localStorage.getItem(STORAGE_TRANSACTION_SPEED) ?? DEFAULT_TRANSACTION_SPEED),
    );

    const customGasPrice = parseUnits(
      Number(JSON.parse(window.localStorage.getItem(STORAGE_CUSTOM_GAS_VALUE) ?? '0')).toFixed(9),
      9,
    );

    if (owracle && owracle.speeds && owracle.speeds.length > 0) {
      const speed = owracle.speeds[speedIdx];
      let maxPriorityFeePerGas = parseUnits(speed.maxPriorityFeePerGas.toFixed(9), 9);
      let maxFeePerGas = parseUnits(speed.maxFeePerGas.toFixed(9), 9);
      // if(effectiveBaseFee && maxPriorityFeePerGas < effectiveBaseFee) {
      //   maxPriorityFeePerGas = effectiveBaseFee;
      // }
      if (effectiveBaseFee && (maxFeePerGas < effectiveBaseFee || speedIdx === 0)) {
        maxFeePerGas = effectiveBaseFee;
      }
      return {
        maxFeePerGas: customGasPrice !== 0n ? customGasPrice : maxFeePerGas,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        gasPrice: undefined, // parseUnits(speed.baseFee.toFixed(9), 9),
      };
    } else {
      this.logger.info('No Owracle data, use on-chain gas info', fee);
      let legacy = 0;
      switch (speedIdx) {
        case 0:
          legacy = fee.gasPrices.low;
          break;
        case 1:
          legacy = fee.gasPrices.standard;
          break;
        case 2:
          legacy = fee.gasPrices.fast;
          break;
        case 3:
          legacy = fee.gasPrices.instant;
          break;
      }

      // custom logic for different chains
      if (Number(environment['CHAIN_ID'] ?? 0) === CHAIN_IDS.REAL) {
        return {
          maxFeePerGas: undefined,
          maxPriorityFeePerGas: undefined,
          gasPrice: parseUnits(legacy.toFixed(9), 9),
        };
      } else {
        // common logic
        if (legacy < fee.estimate.maxPriorityFeePerGas) {
          legacy = fee.estimate.maxPriorityFeePerGas * 1.1;
        }

        let maxPriorityFeePerGas = parseUnits(fee.estimate.maxPriorityFeePerGas.toFixed(9), 9);
        let maxFeePerGas = parseUnits(legacy.toFixed(9), 9);
        // if(effectiveBaseFee && maxPriorityFeePerGas < effectiveBaseFee) {
        //   maxPriorityFeePerGas = effectiveBaseFee;
        // }
        if (effectiveBaseFee && (maxFeePerGas < effectiveBaseFee || speedIdx === 0)) {
          maxFeePerGas = effectiveBaseFee;
        }
        // nobody forbid pay zero tips so
        if (Number(environment['CHAIN_ID'] ?? 0) === CHAIN_IDS.SONIC) {
          maxPriorityFeePerGas = 1n;
        }

        // sonic has very simple logic base fee + 5% is minimal possible gas price
        // but you can send a bit higher, with zero tips will be used base fee anyway
        // base fee does not depend on previous gas price values! it depends only on used gas limits in prev blocks!
        if (Number(environment['CHAIN_ID'] ?? 0) === CHAIN_IDS.SONIC) {
          maxFeePerGas = parseUnits(((fee.estimate.baseFee ?? 0) * 1.1).toFixed(9), 9);
          // if for some reason we will change tips it needs to count in the max gas price
          if (maxPriorityFeePerGas !== 1n) {
            maxFeePerGas += maxPriorityFeePerGas;
          }
        }

        // ### EXPLANATION ###
        // maxFeePerGas is a sun of base fee and priority fee (total max gas price)
        // priority fee is what a miner will get for execute this tx
        // base fee is very volatile and dynamic value - it is how much will be burnt
        // base fee is not setup directly, it calculates dynamically during the tx execution
        // you need to set maxFeePerGas not lower than allowed base fee on this blockchain
        // do not forget that maxPriorityFeePerGas will be extracted from maxFeePerGas, this result should be >= than current base fee
        return {
          maxFeePerGas: customGasPrice !== 0n ? customGasPrice : maxFeePerGas,
          maxPriorityFeePerGas: maxPriorityFeePerGas,
          gasPrice: undefined, // parseUnits(legacy.toFixed(9), 9),
        };
      }
    }
  }

  showNotEnoughTokensInfo() {
    const chain = CHAINS.get(this.chainId) as CHAIN_TYPE;

    if (this.chainId === CHAIN_IDS.NEBULA_TESTNET) {
      window.open(`https://www.sfuelstation.com/claim-sfuel/${this.account}?testnet=true`, '_blank');
    }

    this.dialog.open(InfoDialogComponent, {
      panelClass: 'app-overlay-pane',
      disableClose: true,
      data: {
        infoTitle: 'Not enough network coins!',
        infoDesc: this.translateService.instant(
          chain[CHAIN_FIELDS.IS_TEST_NET] ? 'block-app-buy-coins.test-net' : 'block-app-buy-coins.main-net',
          {
            net: chain[CHAIN_FIELDS.NAME],
            faucetLink: chain[CHAIN_FIELDS.FAUCET_LINK],
          },
        ),
        canClose: true,
      },
    });
  }

  reloadPageOnAccOrNetChange() {
    reloadPage();
  }

  showSpeedUpDialog(): boolean {
    let isHideSpeedup = false;

    if (this.storageService.get(STORAGE_SPEEDUP_HIDE)) {
      isHideSpeedup = JSON.parse(this.storageService.get(STORAGE_SPEEDUP_HIDE)) === CHECKBOX_STATE.CHECKED;
    }

    if (
      isHideSpeedup ||
      !this.web3authService.getUserInfo() ||
      this.speedUpDialogOpened ||
      !!this.privateKey ||
      !this.web3authService.isPasswordEnabled()
    ) {
      return false;
    }
    this.speedUpDialogOpened = true;

    this.dialog.open(StorePrivateKeyDialogComponent, {
      panelClass: 'app-overlay-pane',
      disableClose: true,
      data: {},
    });

    return true;
  }

  showDelegateDialog(): boolean {
    let isHideDelegate = false;

    if (this.storageService.get(STORAGE_DELEGATE_HIDE)) {
      isHideDelegate = JSON.parse(this.storageService.get(STORAGE_DELEGATE_HIDE)) === CHECKBOX_STATE.CHECKED;
    }

    if (
      isHideDelegate ||
      !!this.web3authService.getUserInfo() ||
      this.delegateDialogOpened ||
      !!this.privateKeyDelegated
    ) {
      return false;
    }
    this.delegateDialogOpened = true;

    this.dialog.open(DelegateDialogComponent, {
      panelClass: 'app-overlay-pane',
      disableClose: true,
      data: {},
    });

    return true;
  }
}
