import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { reloadPage } from '@helpers/common-helper';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { STORAGE_CUSTOM_RPC_URL, STORAGE_CUSTOM_SUBGRAPH_URL, StorageService } from '@services/storage.service';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { STORAGE_DELEGATE_HIDE } from '@shared/components/delegate-dialog/delegate-dialog';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { SliderComponent } from '@shared/components/slider/slider.component';
import { STORAGE_SPEEDUP_HIDE } from '@shared/components/store-private-key-dialog/store-private-key-dialog';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import {
  STORAGE_KEY_EFFECTS_VOLUME,
  STORAGE_KEY_MUSIC_VOLUME,
  STORAGE_KEY_PLAY_EFFECTS,
  STORAGE_KEY_PLAY_MUSIC,
  STORAGE_KEY_SETTINGS_PREFIX,
  STORAGE_KEY_SPONSORED_TX,
} from '@shared/constants/settings.constants';
import { takeUntil } from 'rxjs';

enum SOUNDS {
  MUSIC = 'music',
  EFFECTS = 'effects',
}

enum FILTERS {
  NONE = 'none',
  BRIGHTNESS = 'brightness',
  CONTRAST = 'contrast',
  SATURATE = 'saturate',
}

const FILTER_COMPENSATION = 0.5;
export const DEFAULT_TRANSACTION_SPEED = '0';
export const STORAGE_TRANSACTION_SPEED = 'TETU_GAME_TRANSACTION_SPEED';
export const STORAGE_CUSTOM_GAS = 'TETU_GAME_CUSTOM_GAS';
export const STORAGE_CUSTOM_GAS_VALUE = 'TETU_GAME_CUSTOM_GAS_VALUE';
export const STORAGE_DISABLE_ANIMATIONS = 'SACRA_GAME_DISABLE_ANIMATIONS';

@Component({
  selector: 'app-settings-dialog',
  standalone: true,
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'g-flex-column',
  },
  imports: [
    DialogTitleComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    ScratchComponent,
    TranslateModule,
    SliderComponent,
  ],
})
export class SettingsDialogComponent implements OnInit {
  @HostBinding('class.app-window-responsive-background')
  get hasBackground() {
    return !this.isFilterChanging;
  }

  FILTERS = FILTERS;
  SOUNDS = SOUNDS;
  CHECKBOX_STATE = CHECKBOX_STATE;

  filters = {
    [FILTERS.BRIGHTNESS]: {
      id: FILTERS.BRIGHTNESS,
      level: 50,
    },
    [FILTERS.CONTRAST]: {
      id: FILTERS.CONTRAST,
      level: 50,
    },
    [FILTERS.SATURATE]: {
      id: FILTERS.SATURATE,
      level: 50,
    },
  };

  sounds = {
    [SOUNDS.MUSIC]: {
      id: SOUNDS.MUSIC,
      level: 15,
    },
    [SOUNDS.EFFECTS]: {
      id: SOUNDS.EFFECTS,
      level: 100,
    },
  };

  isFilterChanging = false;
  activeFilter: FILTERS;
  sponsoredTxControl = new FormControl(CHECKBOX_STATE.CHECKED);
  isPlayMusicControl = new FormControl(CHECKBOX_STATE.CHECKED);
  isPlayEffectsControl = new FormControl(CHECKBOX_STATE.CHECKED);
  askSpeedUpControl = new FormControl(CHECKBOX_STATE.NONE);
  askDelegateControl = new FormControl(CHECKBOX_STATE.NONE);
  customGasControl = new FormControl(CHECKBOX_STATE.NONE);
  customGasValueControl = new FormControl('10');
  subgraphUrlControl = new FormControl(CHECKBOX_STATE.NONE);
  subgraphUrlValueControl = new FormControl('');
  rpcUrlControl = new FormControl(CHECKBOX_STATE.NONE);
  rpcUrlValueControl = new FormControl('');
  disableAnimationsControl = new FormControl(CHECKBOX_STATE.NONE);

  transactionSpeedValue = 0;
  transactionSpeedMax = 3;
  transactionSpeedLabels: string[] = [];

  constructor(
    @Inject(DIALOG_DATA) public data: HeroStatItemInputInterface,
    private dialogRef: DialogRef<null, SettingsDialogComponent>,
    private storageService: StorageService,
    private providerService: ProviderService,
    private destroy$: DestroyService,
    private translateService: TranslateService,
    private soundService: SoundService,
  ) {}

  ngOnInit() {
    this.transactionSpeedLabels = Object.values(this.translateService.instant('settings-dialog.transaction-speeds'));

    if (this.storageService.get(STORAGE_TRANSACTION_SPEED)) {
      this.transactionSpeedValue = JSON.parse(this.storageService.get(STORAGE_TRANSACTION_SPEED));
    } else {
      this.onSpeedChange(Number(DEFAULT_TRANSACTION_SPEED));
    }

    if (this.storageService.get(STORAGE_SPEEDUP_HIDE)) {
      const hideSpeedup = JSON.parse(this.storageService.get(STORAGE_SPEEDUP_HIDE));

      this.askSpeedUpControl.setValue(
        hideSpeedup === CHECKBOX_STATE.CHECKED ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED,
      );
    }

    if (this.storageService.get(STORAGE_DELEGATE_HIDE)) {
      const hideDelegate = JSON.parse(this.storageService.get(STORAGE_DELEGATE_HIDE));

      this.askDelegateControl.setValue(
        hideDelegate === CHECKBOX_STATE.CHECKED ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED,
      );
    }

    if (this.storageService.get(STORAGE_DISABLE_ANIMATIONS)) {
      const disableAnimations = JSON.parse(this.storageService.get(STORAGE_DISABLE_ANIMATIONS));

      this.disableAnimationsControl.setValue(disableAnimations);
    }

    if (this.storageService.get(STORAGE_CUSTOM_GAS)) {
      const customGas = JSON.parse(this.storageService.get(STORAGE_CUSTOM_GAS));

      this.customGasControl.setValue(
        customGas === CHECKBOX_STATE.CHECKED ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED,
      );
    }

    if (this.storageService.get(STORAGE_CUSTOM_GAS_VALUE)) {
      const customGasValue = JSON.parse(this.storageService.get(STORAGE_CUSTOM_GAS_VALUE));

      this.customGasValueControl.setValue(customGasValue);
    }

    this.customGasValueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const value = this.customGasValueControl.value;
      this.storageService.set(STORAGE_CUSTOM_GAS_VALUE, JSON.stringify(value));
      if (!!value && Number(value) > 0) {
        this.providerService.getActualFees().subscribe();
      }
    });

    if (this.storageService.get(STORAGE_CUSTOM_SUBGRAPH_URL)) {
      this.subgraphUrlValueControl.setValue(this.storageService.get(STORAGE_CUSTOM_SUBGRAPH_URL));
      this.subgraphUrlControl.setValue(CHECKBOX_STATE.CHECKED);
    } else {
      this.subgraphUrlValueControl.setValue(environment.SUBGRAPH_URI);
      this.subgraphUrlValueControl.disable();
    }

    this.subgraphUrlControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.saveSubgraphUrl());

    if (this.storageService.get(STORAGE_CUSTOM_RPC_URL)) {
      this.rpcUrlValueControl.setValue(this.storageService.get(STORAGE_CUSTOM_RPC_URL));
      this.rpcUrlControl.setValue(CHECKBOX_STATE.CHECKED);
    } else {
      this.rpcUrlValueControl.setValue(environment.RPC_PROVIDER_URL);
      this.rpcUrlValueControl.disable();
    }

    this.rpcUrlControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.saveRpcUrl());

    this.customGasControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const isChecked = this.customGasControl.value === CHECKBOX_STATE.CHECKED;
      this.storageService.set(
        STORAGE_CUSTOM_GAS,
        JSON.stringify(isChecked ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED),
      );
      if (isChecked) {
        this.storageService.set(STORAGE_CUSTOM_GAS_VALUE, JSON.stringify(this.customGasValueControl.value));
      } else {
        this.customGasValueControl.setValue('0');
        this.storageService.set(STORAGE_CUSTOM_GAS_VALUE, JSON.stringify(0));
        this.providerService.getActualFees().subscribe();
      }
    });

    this.askSpeedUpControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(
        STORAGE_SPEEDUP_HIDE,
        JSON.stringify(
          this.askSpeedUpControl.value === CHECKBOX_STATE.CHECKED ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED,
        ),
      );
    });

    this.askDelegateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(
        STORAGE_DELEGATE_HIDE,
        JSON.stringify(
          this.askDelegateControl.value === CHECKBOX_STATE.CHECKED ? CHECKBOX_STATE.NONE : CHECKBOX_STATE.CHECKED,
        ),
      );
    });

    this.disableAnimationsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_DISABLE_ANIMATIONS, JSON.stringify(this.disableAnimationsControl.value));
    });

    Object.keys(this.filters).forEach(key => {
      if (this.storageService.get(`${STORAGE_KEY_SETTINGS_PREFIX}${key}`)) {
        this.filters[key].level = +this.storageService.get(`${STORAGE_KEY_SETTINGS_PREFIX}${key}`);
      }
    });

    Object.keys(this.sounds).forEach(key => {
      const storageKey = key === SOUNDS.MUSIC ? STORAGE_KEY_MUSIC_VOLUME : STORAGE_KEY_EFFECTS_VOLUME;

      if (this.storageService.get(`${storageKey}`)) {
        this.sounds[key].level = +this.storageService.get(`${storageKey}`);
      }
    });

    if (this.storageService.get(STORAGE_KEY_PLAY_MUSIC)) {
      this.isPlayMusicControl.setValue(this.storageService.get(STORAGE_KEY_PLAY_MUSIC) as CHECKBOX_STATE);
    }

    if (this.storageService.get(STORAGE_KEY_SPONSORED_TX)) {
      this.sponsoredTxControl.setValue(this.storageService.get(STORAGE_KEY_SPONSORED_TX) as CHECKBOX_STATE);
    }

    if (this.storageService.get(STORAGE_KEY_PLAY_EFFECTS)) {
      this.isPlayEffectsControl.setValue(this.storageService.get(STORAGE_KEY_PLAY_EFFECTS) as CHECKBOX_STATE);
    }

    this.isPlayMusicControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_KEY_PLAY_MUSIC, String(this.isPlayMusicControl.value));

      this.soundService.setMusicVolume(
        this.isPlayMusicControl.value === CHECKBOX_STATE.NONE ? 0 : this.sounds[SOUNDS.MUSIC].level / 100,
        false,
      );
    });

    this.sponsoredTxControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_KEY_SPONSORED_TX, String(this.sponsoredTxControl.value));
      this.providerService.sponsoredTransactionsEnabled = this.sponsoredTxControl.value === CHECKBOX_STATE.CHECKED;
    });

    this.isPlayEffectsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.storageService.set(STORAGE_KEY_PLAY_EFFECTS, String(this.isPlayEffectsControl.value));

      this.soundService.setEffectsVolume(
        this.isPlayEffectsControl.value === CHECKBOX_STATE.NONE ? 0 : this.sounds[SOUNDS.EFFECTS].level / 100,
        false,
      );
    });
  }

  close(): void {
    this.dialogRef.close(null);
  }

  onFilterLevelChange(level: number, filter: FILTERS) {
    this.activeFilter = filter;
    this.isFilterChanging = true;

    this.filters[filter].level = level;

    if (document.querySelector('.app-overlay-pane')) {
      (document.querySelector('.app-overlay-pane') as HTMLElement).style['backdrop-filter'] = 'none';
    }

    if (document.querySelector('.app-overlay-pane-full-width')) {
      (document.querySelector('.app-overlay-pane-full-width') as HTMLElement).style['backdrop-filter'] = 'none';
    }

    this.setFilters();
  }

  onFilterLevelChanged() {
    this.activeFilter = FILTERS.NONE;
    this.isFilterChanging = false;

    if (document.querySelector('.app-overlay-pane')) {
      (document.querySelector('.app-overlay-pane') as HTMLElement).style['backdrop-filter'] = 'blur(5px)';
    }

    if (document.querySelector('.app-overlay-pane-full-width')) {
      (document.querySelector('.app-overlay-pane-full-width') as HTMLElement).style['backdrop-filter'] = 'blur(5px)';
    }
  }

  onSoundLevelChange(level: number, sound: SOUNDS) {
    if (sound === SOUNDS.MUSIC) {
      this.soundService.setMusicVolume(level / 100);
    } else {
      this.soundService.setEffectsVolume(level / 100);
    }

    const storageKey = sound === SOUNDS.MUSIC ? STORAGE_KEY_MUSIC_VOLUME : STORAGE_KEY_EFFECTS_VOLUME;

    this.storageService.set(`${storageKey}`, level);

    this.sounds[sound].level = level;
  }

  onSpeedChange(speed: number) {
    this.transactionSpeedValue = speed;

    this.storageService.set(STORAGE_TRANSACTION_SPEED, JSON.stringify(this.transactionSpeedValue));
  }

  reset() {
    Object.keys(this.filters).forEach(key => {
      if (this.storageService.get(`${STORAGE_KEY_SETTINGS_PREFIX}${key}`)) {
        this.filters[key].level = 50;
      }
    });

    this.setFilters();
  }

  private setFilters() {
    const filterString = Object.keys(this.filters)
      .map(key => {
        this.storageService.set(`${STORAGE_KEY_SETTINGS_PREFIX}${key}`, this.filters[key].level);

        return `${key}(${this.filters[key].level / 100 + FILTER_COMPENSATION})`;
      })
      .join(' ');

    (document.querySelector('body') as HTMLBodyElement).style.filter = filterString;
  }

  // refresh if url changed
  saveSubgraphUrl(): void {
    if (this.subgraphUrlControl.value === CHECKBOX_STATE.NONE) {
      this.subgraphUrlValueControl.setValue(environment.SUBGRAPH_URI);
      this.storageService.remove(STORAGE_CUSTOM_SUBGRAPH_URL);
      this.subgraphUrlValueControl.disable();
    } else {
      this.storageService.set(STORAGE_CUSTOM_SUBGRAPH_URL, this.subgraphUrlValueControl.value!);
      this.subgraphUrlValueControl.enable();
    }
  }

  // refresh if url changed
  saveRpcUrl(): void {
    if (this.rpcUrlControl.value === CHECKBOX_STATE.NONE) {
      this.rpcUrlValueControl.setValue(environment.RPC_PROVIDER_URL);
      this.storageService.remove(STORAGE_CUSTOM_RPC_URL);
      this.rpcUrlValueControl.disable();
    } else {
      this.storageService.set(STORAGE_CUSTOM_RPC_URL, this.rpcUrlValueControl.value!);
      this.rpcUrlValueControl.enable();
    }
  }

  saveUrl() {
    const subgraphUrl = this.subgraphUrlValueControl.value;
    if (subgraphUrl && subgraphUrl !== environment.SUBGRAPH_URI) {
      this.storageService.set(STORAGE_CUSTOM_SUBGRAPH_URL, subgraphUrl);
    }

    const rpcUrl = this.rpcUrlValueControl.value;
    if (rpcUrl && rpcUrl !== environment.RPC_PROVIDER_URL) {
      this.storageService.set(STORAGE_CUSTOM_RPC_URL, rpcUrl);
    }

    reloadPage();
  }
}
