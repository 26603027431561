import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { ItemDescriptionComponent } from '@shared/components/item-description/item-description.component';
import { ItemActionType } from '@shared/constants/inventory.constants';

@Component({
  selector: 'app-item-description-dialog',
  standalone: true,
  templateUrl: './item-description-dialog.component.html',
  styleUrls: ['./item-description-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column app-window-responsive-background--full-width g-scroll-y',
  },
  imports: [ItemDescriptionComponent],
})
export class ItemDescriptionDialogComponent {
  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      chainId?: number;
      item: ItemEntity;
      slot?: number;
      hero?: HeroEntity;
      isShowButtons?: boolean;
      isApplyItem?: boolean;
      applyItemLabel?: string;
      isHideDurability?: boolean;
      isCanUse?: boolean;
    },
    private dialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent>,
  ) {}

  takeOff({ item, hero, equippedSlot }) {
    if (this.data.hero && this.data.slot) {
      this.dialogRef.close({
        takeOff: {
          item,
          hero,
          equippedSlot,
        },
      });
    }
  }

  use({ useItem }) {
    this.dialogRef.close({ useItem });
  }

  close({ page, isApply }: { page?: string | null; isApply?: boolean | null }): void {
    this.dialogRef.close({ page, isApply });
  }

  onItemRepair() {
    this.dialogRef.close({ isNeedToUpdate: true });
  }

  onItemTransfer() {
    this.dialogRef.close({ isNeedToUpdate: true });
  }

  onItemForge() {
    this.dialogRef.close({ isNeedToUpdate: true });
  }
}
