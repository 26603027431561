import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { getTokenType } from '@helpers/data-helper';
import { Formatter } from '@helpers/formatter';
import { DestroyService } from '@services/destroy.service';
import { UniswapV3Service } from '@services/onchain/uniswap-v3.service';
import { formatUnits } from 'ethers';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-balance',
  standalone: true,
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center',
  },
})
export class BalanceComponent implements OnInit, OnChanges {
  @HostBinding('class.g-flex--reverse')
  @Input()
  isReverse = false;
  @Input() tokenType: string | null = null;
  @Input() token: string | null = null;
  @Input() size: 'default' | 'small' | 'medium' = 'default';
  @Input() balance: string | number;
  @Input() isShowBalanceUsd = false;
  @Input() isShowZeroBalanceUsd = false;
  @Input() isShowZeroBalance = false;
  @Input() balanceUnformatted: number | undefined = undefined;
  @Input() isUsdNextLine = false;
  @Input() tokenPriceUsd = 0;
  @Input() oldBalance: string | number;
  @Input() oldBalanceUnformatted: number | undefined = undefined;

  tokenName: string | null = null;
  balanceUsd: string;
  oldBalanceUsd: string;

  protected readonly formatUnits = formatUnits;

  constructor(
    private uniswapV3Service: UniswapV3Service,
    private changeDetectorRef: ChangeDetectorRef,
    private destroy$: DestroyService,
  ) {}

  ngOnInit() {
    this.tokenName = this.getTokenName();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { balance, balanceUnformatted, oldBalanceUnformatted, oldBalance } = changes;

    this.tokenName = this.getTokenName();

    if (this.isShowBalanceUsd && balance && balance.previousValue !== balance.currentValue) {
      if (this.tokenName === 'sacra') {
        this.uniswapV3Service
          .sacraPrice$(Number(environment['CHAIN_ID']))
          .pipe(takeUntil(this.destroy$))
          .subscribe(p => {
            this.tokenPriceUsd = this.tokenPriceUsd === 0 ? p : this.tokenPriceUsd;

            this.balanceUsd = (
              (balanceUnformatted?.currentValue ? balanceUnformatted?.currentValue : +(balance?.currentValue ?? 0)) *
              this.tokenPriceUsd
            ).toFixed(18);

            this.oldBalanceUsd = (
              (oldBalanceUnformatted?.currentValue
                ? oldBalanceUnformatted?.currentValue
                : +(oldBalance?.currentValue ?? 0)) * this.tokenPriceUsd
            ).toFixed(18);

            this.changeDetectorRef.detectChanges();
          });
      }
      if (this.tokenName === 'ftm' || this.tokenName === 's') {
        this.uniswapV3Service
          .nativePrice$(Number(environment['CHAIN_ID']))
          .pipe(takeUntil(this.destroy$))
          .subscribe(p => {
            this.tokenPriceUsd = p;
            this.balanceUsd = (
              (balanceUnformatted?.currentValue ? balanceUnformatted?.currentValue : +balance.currentValue) *
              this.tokenPriceUsd
            ).toFixed(18);
            this.changeDetectorRef.detectChanges();
          });
      }
      if (this.tokenName === 'usdc') {
        this.tokenPriceUsd = 1;
        this.balanceUsd = (
          (balanceUnformatted?.currentValue ? balanceUnformatted?.currentValue : +balance.currentValue) *
          this.tokenPriceUsd
        ).toFixed(18);
        this.changeDetectorRef.detectChanges();
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  private getTokenName() {
    if (this.tokenType) {
      return this.tokenType;
    }

    if (!this.token) {
      console.error(`Unknown token ${this.token}`);
      return 'sacra';
    }

    return getTokenType(this.token, Number(environment['CHAIN_ID']));
  }

  formatAmount(number: string) {
    return Formatter.formatCurrency(+number);
  }

  checkBalance() {
    if (this.isShowZeroBalance) {
      return this.balance !== undefined && this.balance !== null;
    }
    return this.balance;
  }
}
