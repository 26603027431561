import { environment } from '@environments/environment';
import { getMainDomain } from '@helpers/data-helper';
import { DropdownItemModel } from '@shared/components/dropdown/model/dropdown-item.model';

export enum CHAIN_IDS {
  ETH = 1,
  POLYGON = 137,
  MUMBAI = 80001,
  SEPOLIA = 11155111,
  OP_SEPOLIA = 11155420,
  SKALE_TEST = 1351057110,
  IMMT = 13472,
  SONIC = 146,
  FANTOM = 250,
  REAL = 111188,
  NEBULA_TESTNET = 37084624,
}

export enum CHAIN_FIELDS {
  ID = 'id',
  NAME = 'name',
  DAPP_URL = 'DAPP_URL',
  ICON_NAME = 'icon_name',
  TOKEN_NAME = 'token_name',
  IS_TEST_NET = 'is_test_net',
  FAUCET_LINK = 'faucet_link',
  AVAILABLE_ON_UI = 'AVAILABLE_ON_UI',
  BLOCK_EXPLORER = 'BLOCK_EXPLORER',
  COINS_FOR_DELEGATION = 'COINS_FOR_DELEGATION',
}

export type CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS;
  [CHAIN_FIELDS.NAME]: string;
  [CHAIN_FIELDS.DAPP_URL]: string;
  [CHAIN_FIELDS.ICON_NAME]: string;
  [CHAIN_FIELDS.TOKEN_NAME]: string;
  [CHAIN_FIELDS.IS_TEST_NET]: boolean;
  [CHAIN_FIELDS.FAUCET_LINK]?: string;
  [CHAIN_FIELDS.AVAILABLE_ON_UI]?: boolean;
  [CHAIN_FIELDS.BLOCK_EXPLORER]: string;
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: number;
};

export const POLYGON_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.POLYGON,
  [CHAIN_FIELDS.NAME]: 'Polygon',
  [CHAIN_FIELDS.DAPP_URL]: 'https://polygon.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'matic',
  [CHAIN_FIELDS.TOKEN_NAME]: 'MATIC',
  [CHAIN_FIELDS.IS_TEST_NET]: false,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: false,
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://polygonscan.com/',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 100,
};

export const OP_SEPOLIA_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.OP_SEPOLIA,
  [CHAIN_FIELDS.NAME]: 'Optimism Sepolia',
  [CHAIN_FIELDS.DAPP_URL]: 'https://op-sepolia.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'ETH',
  [CHAIN_FIELDS.TOKEN_NAME]: 'ETH',
  [CHAIN_FIELDS.IS_TEST_NET]: true,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: false,
  [CHAIN_FIELDS.FAUCET_LINK]: 'https://app.optimism.io/faucet/',
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://sepolia-optimism.etherscan.io/',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 10,
};

export const SONIC_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.SONIC,
  [CHAIN_FIELDS.NAME]: 'Sonic',
  [CHAIN_FIELDS.DAPP_URL]: 'https://sonic.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'S',
  [CHAIN_FIELDS.TOKEN_NAME]: 'S',
  [CHAIN_FIELDS.IS_TEST_NET]: false,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: true,
  [CHAIN_FIELDS.FAUCET_LINK]: 'https://onramp.money',
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://sonicscan.org',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 10,
};

export const FANTOM_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.FANTOM,
  [CHAIN_FIELDS.NAME]: 'Fantom',
  [CHAIN_FIELDS.DAPP_URL]: 'https://fantom.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'FTM',
  [CHAIN_FIELDS.TOKEN_NAME]: 'FTM',
  [CHAIN_FIELDS.IS_TEST_NET]: false,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: true,
  [CHAIN_FIELDS.FAUCET_LINK]: 'https://onramp.money/',
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://ftmscan.com',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 10,
};

export const REAL_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.REAL,
  [CHAIN_FIELDS.NAME]: 're.al',
  [CHAIN_FIELDS.DAPP_URL]: 'https://real.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'ETH',
  [CHAIN_FIELDS.TOKEN_NAME]: 'reETH',
  [CHAIN_FIELDS.IS_TEST_NET]: false,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: true,
  [CHAIN_FIELDS.FAUCET_LINK]: 'https://www.re.al/bridge',
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://explorer.re.al',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 0.01,
};

export const NEBULA_TESTNET_CHAIN: CHAIN_TYPE = {
  [CHAIN_FIELDS.ID]: CHAIN_IDS.NEBULA_TESTNET,
  [CHAIN_FIELDS.NAME]: 'Nebula Testnet',
  [CHAIN_FIELDS.DAPP_URL]: 'https://nebula-testnet.' + getMainDomain(),
  [CHAIN_FIELDS.ICON_NAME]: 'sFUEL',
  [CHAIN_FIELDS.TOKEN_NAME]: 'sFUEL',
  [CHAIN_FIELDS.IS_TEST_NET]: true,
  [CHAIN_FIELDS.AVAILABLE_ON_UI]: false,
  [CHAIN_FIELDS.FAUCET_LINK]: 'https://www.sfuelstation.com/connect-wallet',
  [CHAIN_FIELDS.BLOCK_EXPLORER]: 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/',
  [CHAIN_FIELDS.COINS_FOR_DELEGATION]: 0.01,
};

export const CHAINS = new Map<number, CHAIN_TYPE>([
  // [CHAIN_IDS.POLYGON, POLYGON_CHAIN],
  // [CHAIN_IDS.MUMBAI, MUMBAI_CHAIN],
  // [CHAIN_IDS.SEPOLIA, SEPOLIA_CHAIN],
  // [CHAIN_IDS.OP_SEPOLIA, OP_SEPOLIA_CHAIN],
  [CHAIN_IDS.SONIC, SONIC_CHAIN],
  [CHAIN_IDS.FANTOM, FANTOM_CHAIN],
  [CHAIN_IDS.REAL, REAL_CHAIN],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_CHAIN],
  // [CHAIN_IDS.SKALE_TEST, SKALE_TEST_CHAIN],
  // [CHAIN_IDS.IMMT, IMMT_CHAIN],
]);

export function getChainByChainId(chainId: number) {
  return CHAINS.get(chainId) ?? FANTOM_CHAIN;
}

export function getChainOptions(): DropdownItemModel[] {
  const expectedChain = environment.CHAIN_ID;
  const isStaging = environment.STAGING === 'true';
  return Array.from(CHAINS)
    .map(it => it[1])
    .filter(it => isStaging || it[CHAIN_FIELDS.AVAILABLE_ON_UI])
    .filter(it => it[CHAIN_FIELDS.ID].toString() === expectedChain)
    .map(it => {
      return new DropdownItemModel({
        id: it[CHAIN_FIELDS.ID].toString(),
        label: it[CHAIN_FIELDS.NAME],
        prefixIconPath: `/assets/images/ui/icons/tokens/${it[CHAIN_FIELDS.ICON_NAME].toLowerCase()}.png`,
      });
    });
}

export function getGuidLink(chainId: number) {
  if (chainId === CHAIN_IDS.FANTOM) {
    return "Follow this <a href='https://bit.ly/sacra24' target='_blank'>guide</a> for more information.";
  }
  const link = CHAINS.get(chainId)?.faucet_link ?? '';
  return `Get network coins <a href='${link}' target='_blank'>here</a>.`;
}
