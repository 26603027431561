<app-dialog-title
  class="g-flex-column__item-fixed"
  title="Fragility Restored"
  (closeDialog)="close()">
</app-dialog-title>

<div class="container g-flex-column g-flex--align-center">
  <div class="title__text">Now your item has restored fragility, you can find it in your inventory.</div>

  <button
    class="btn-apply app-btn-blue-3"
    (click)="close()">
    {{ 'repair-result-dialog.buttons.close' | translate }}
  </button>
</div>
